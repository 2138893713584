import { ReactElement } from 'react';
import styles from './user-details-page-section.module.scss';
import { User } from '@techshift/callie-private-api-typescript';
import { PageSection } from '../../../shared/components';
import { Text } from '../../../ui/components';

type UserDetailsPageSectionProps = {
	user: User;
};
export function UserDetailsPageSection(
	props: UserDetailsPageSectionProps
): ReactElement {
	const { user } = props;

	return (
		<div className={styles.container}>
			<PageSection title={'User Details'}>
				<div className={styles.userContainer}>
					<div className={styles.userDetailsItemContainer}>
						<Text>Name</Text>
						<Text bold>{user.firstName + ' ' + user.lastName}</Text>
					</div>
					<div className={styles.userDetailsItemContainer}>
						<Text>Phone</Text>
						<Text bold>{user.phone || 'N/A'}</Text>
					</div>
					<div className={styles.userDetailsItemContainer}>
						<Text>Email</Text>
						<Text bold>{user.email || 'N/A'}</Text>
					</div>
				</div>
			</PageSection>
		</div>
	);
}
