import { ReactElement } from 'react';
import { Button, Classes, Dialog } from '@blueprintjs/core';
import { DeleteUserSession } from '..';

export type DeleteUserSessionDialogProps = {
	userId: string;
	sessionId: string;
	isOpen?: boolean;
	onSuccess?: () => void;
	onClose?: () => void;
};
export function DeleteUserSessionDialog(
	props: DeleteUserSessionDialogProps
): ReactElement {
	const { userId, sessionId, isOpen, onSuccess, onClose } = props;

	return (
		<Dialog isOpen={isOpen}>
			<div className={Classes.DIALOG_BODY}>
				<DeleteUserSession
					userId={userId}
					sessionId={sessionId}
					onSuccess={onSuccess}
				/>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={onClose}>Close</Button>
				</div>
			</div>
		</Dialog>
	);
}
