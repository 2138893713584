import { H2 } from '@blueprintjs/core';
import { ReactElement } from 'react';

export function AdminsRolesPage(): ReactElement {
	return (
		<div>
			<H2 className="bp4-heading">Admins roles</H2>
		</div>
	);
}
