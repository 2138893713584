import { ReactElement } from 'react';
import styles from './login.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Login } from '../../me/components';

export function LoginPage(): ReactElement {
	const navigate = useNavigate();
	const location = useLocation();

	// eslint-disable-next-line
	const from = (location.state as any)?.from?.pathname || '/';

	return (
		<div className={styles.container}>
			<Login
				onSuccess={() => {
					navigate(from, { replace: true });
				}}
			/>
		</div>
	);
}
