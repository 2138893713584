import { ReactElement } from 'react';
import { PaginationButtonsView } from './pagination-buttons-view';

type PaginationButtonsProps = {
	page: {
		limit: number;
		offset: number;
		hasMore: boolean;
	} | null;
	onSetOffset: (offset: number) => void;
};

export function PaginationButtons(props: PaginationButtonsProps): ReactElement {
	const { page, onSetOffset } = props;

	return (
		<PaginationButtonsView
			page={page}
			onPreviousPress={() => {
				if (page) {
					onSetOffset(page.offset - page.limit);
				}
			}}
			onNextPress={() => {
				if (page) {
					onSetOffset(page.offset + page.limit);
				}
			}}
		/>
	);
}
