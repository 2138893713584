import { ReactElement, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, NonIdealState, Spinner } from '@blueprintjs/core';
import { useOpenApi } from '../../../core/providers';
import { User } from '@techshift/callie-private-api-typescript';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { UserSidebar } from '../../../users/components';
import { UserGuardians } from '../../../users/components/user-guardians/user-guardians';
import { UserSessionsPageSection } from '../../../users/components/user-sessions-page-section';
import { TasksPageSection } from '../../../tasks/components';

export function UserIdPage(): ReactElement {
	const { userId } = useParams();
	const navigate = useNavigate();

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [user, setUser] = useState<User | null>(null);

	const fetch = useCallback((id: string) => {
		setUser(null);
		setLoading(true);
		setError('');
		openApi.users
			.getUser(id)
			.then((response) => {
				setUser(response.data.data.user);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'USER_NOT_FOUND') {
							setError('User not found');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	useEffect(() => {
		if (!userId) {
			return;
		}

		fetch(userId);
	}, [userId, fetch]);

	return (
		<div style={{ flex: 1, paddingTop: 25, paddingLeft: 25, display: 'flex' }}>
			{loading && <NonIdealState icon={<Spinner />} title={'Loading'} />}
			{error && (
				<NonIdealState
					icon={'error'}
					title={'Error'}
					description={error}
					action={
						<Button
							outlined
							onClick={() => {
								if (userId) {
									fetch(userId);
								}
							}}
						>
							Retry
						</Button>
					}
				/>
			)}

			{user && (
				<>
					<UserSidebar
						user={user}
						onUpdateUser={setUser}
						onDeleteUser={() => {
							navigate(`/users`);
						}}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							flex: 1,
							padding: 20
						}}
					>
						<UserGuardians userId={user.id} />
						<br />
						<TasksPageSection
							userId={user.id}
							onTaskClick={(task) => {
								navigate(`/tasks/${task.id}`);
							}}
						/>
						<br />
						<UserSessionsPageSection userId={user.id} />
					</div>
				</>
			)}
		</div>
	);
}
