import { ReactElement, useEffect, useState, useRef } from 'react';
import {
	ArcOperatorsResponseData,
	ArcOperator
} from '@techshift/callie-private-api-typescript';
import styles from './arc-operators-table.module.scss';
import { useOpenApi } from '../../../core/providers';
import { HTMLTable } from '@blueprintjs/core';
import { TableFooter } from '../../../ui/components';

type ArcOperatorsTableProps = {
	initialLimit?: number;
	initialOffset?: number;
	onOffsetChange?: (offset: number) => void;
	onArcOperatorClick?: (operator: ArcOperator) => void;
	showDeleted?: boolean;
};

export function ArcOperatorsTable(props: ArcOperatorsTableProps): ReactElement {
	const {
		initialLimit,
		initialOffset,
		onOffsetChange,
		onArcOperatorClick,
		showDeleted = false
	} = props;

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);

	const [arcOperatorsResponse, setArcOperatorsResponse] =
		useState<ArcOperatorsResponseData | null>(null);

	const [limit] = useState<number>(initialLimit || 25);
	const [offset, setOffset] = useState<number>(initialOffset || 0);

	useEffect(() => {
		setArcOperatorsResponse(null);
		openApiRef.current.arc
			.getArcOperators({ limit, offset, deleted: showDeleted })
			.then((response) => {
				setArcOperatorsResponse(response.data.data);
			});
	}, [limit, offset, showDeleted]);

	useEffect(() => {
		onOffsetChange?.(offset);
	}, [onOffsetChange, offset]);

	return (
		<div className={styles.container}>
			<HTMLTable striped interactive>
				<thead>
					<tr>
						<th>FIRST NAME</th>
						<th>LAST NAME</th>
						<th>EMAIL</th>
						<th>ID</th>
						<th>CREATED</th>
						{showDeleted && <th>DELETED</th>}
					</tr>
				</thead>
				<tbody>
					{arcOperatorsResponse?.operators.data.map((operator) => (
						<tr
							key={operator.id}
							onClick={() => onArcOperatorClick?.(operator)}
						>
							<td>{operator.firstName}</td>
							<td>{operator.lastName}</td>
							<td>{operator.email}</td>
							<td>{operator.id}</td>
							<td>{new Date(operator.createdAt).toLocaleString()}</td>
							{showDeleted && (
								<td>
									{operator.deletedAt
										? new Date(operator.deletedAt).toLocaleString()
										: 'N/A'}
								</td>
							)}
						</tr>
					))}
				</tbody>
			</HTMLTable>

			<br />

			<TableFooter
				page={
					arcOperatorsResponse
						? {
								offset: arcOperatorsResponse.operators.offset,
								limit: arcOperatorsResponse.operators.limit,
								hasMore: arcOperatorsResponse.operators.hasMore,
								total: arcOperatorsResponse.operators.total
						  }
						: null
				}
				onSetOffset={setOffset}
			/>
			<br />
		</div>
	);
}
