import { ReactElement, useEffect, useState } from 'react';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { User } from '@techshift/callie-private-api-typescript';
import { RestoreUserForm } from '..';

export type RestoreUserProps = {
	user: User;
	onSuccess?: (user: User) => void;
};
export function RestoreUser(props: RestoreUserProps): ReactElement {
	const { user, onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = () => {
		setLoading(true);
		setError('');
		openApi.users
			.updateUser(user.id, { deletedAt: null })
			.then((response) => {
				AppToaster.show({ message: 'User restored', intent: 'success' });
				onSuccess?.(response.data.data.user);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'USER_NOT_FOUND') {
							setError('Cannot restore user as they do not exist');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return <RestoreUserForm id={user.id} loading={loading} onSubmit={onSubmit} />;
}
