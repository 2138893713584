import { ReactElement } from 'react';
import styles from './user-sessions-page-section.module.scss';
import { PageSection } from '../../../shared/components';
import { UserSessionsTable } from '../user-sessions-table';

type UserSessionsPageSectionProps = {
	userId: string;
};
export function UserSessionsPageSection(
	props: UserSessionsPageSectionProps
): ReactElement {
	const { userId } = props;

	return (
		<div className={styles.container}>
			<PageSection title={'Login Sessions'}>
				<UserSessionsTable initialQuery={{ limit: 5, userId }} />
			</PageSection>
		</div>
	);
}
