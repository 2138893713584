import { ReactElement, useCallback } from 'react';
import styles from './arc-operators-overview.page.module.scss';
import {
	ArcOperatorsTable,
	CreateArcOperatorButton
} from '../../../arc/components';
import { ButtonGroup, H2 } from '@blueprintjs/core';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function ArcOperatorsOverviewPage(): ReactElement {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const onOffsetChange = useCallback(
		(offset: number) => {
			setSearchParams({ offset: offset.toString() });
		},
		[setSearchParams]
	);

	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<H2>ARC overview</H2>
				<ButtonGroup>
					<CreateArcOperatorButton />
				</ButtonGroup>
			</div>
			<ArcOperatorsTable
				initialOffset={Number(searchParams.get('offset')) || undefined}
				onOffsetChange={onOffsetChange}
				onArcOperatorClick={(operator) => navigate(`/arc/${operator.id}`)}
			/>
		</div>
	);
}
