import { ReactElement } from 'react';
import styles from './focus-button.module.scss';
import locate from './locate.svg';
import locateFocused from './locate-focused.svg';

type FocusButtonProps = {
	onClick: () => void;
	focused?: boolean;
};
export function FocusButton(props: FocusButtonProps): ReactElement {
	const { onClick, focused = false } = props;
	const classNames: string[] = [styles.button];

	if (focused) {
		classNames.push(styles.focused);
	}
	return (
		<button className={classNames.join(' ')} onClick={onClick}>
			<img
				src={focused ? locateFocused : locate}
				alt="Locate"
				className={styles.img}
			/>
		</button>
	);
}
