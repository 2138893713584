import { ReactElement, useCallback } from 'react';
import {
	Task,
	TasksResponseData
} from '@techshift/callie-private-api-typescript';
import styles from './tasks-table-view.module.scss';
import { HTMLTable, Icon } from '@blueprintjs/core';
import { PaginationButtonsView } from '../../../ui/components';
import { Text } from '../../../ui/components/text';

type TasksTableViewProps = {
	data: TasksResponseData | null;
	onTaskClick?: (task: Task) => void;
	onCreatedAtSortClick?: () => void;
	showDeleted?: boolean;
	sortBy?: 'timeoutAt' | 'alertedAt' | 'closedAt' | 'createdAt';
	sortOrder?: 'asc' | 'desc';
	onPreviousPress: () => void;
	onNextPress: () => void;
};

export function TasksTableView(props: TasksTableViewProps): ReactElement {
	const {
		data,
		onTaskClick,
		onCreatedAtSortClick,
		showDeleted,
		sortBy,
		sortOrder,
		onPreviousPress,
		onNextPress
	} = props;

	const renderButton = useCallback(
		(
			text: string,
			thisSortBy:
				| 'timeoutAt'
				| 'alertedAt'
				| 'closedAt'
				| 'createdAt'
				| undefined,
			defaultSortOrder: 'asc' | 'desc',
			clickCallback?: () => void
		) => {
			return (
				<button className={styles.headerButton} onClick={clickCallback}>
					{text}
					{sortBy === thisSortBy && (
						<Icon
							icon={sortOrder === 'asc' ? 'sort-asc' : 'sort-desc'}
							className={styles.iconSelected}
						/>
					)}
					{sortBy !== thisSortBy && (
						<Icon
							icon={defaultSortOrder === 'asc' ? 'sort-asc' : 'sort-desc'}
							className={styles.icon}
						/>
					)}
				</button>
			);
		},
		[sortBy, sortOrder]
	);

	return (
		<div className={styles.container}>
			<HTMLTable striped interactive>
				<thead>
					<tr>
						<th>ID</th>
						<th>STATUS</th>
						<th>ALERTED</th>
						<th>TYPE</th>
						<th>NAME</th>
						<th>USER ID</th>
						<th>
							{renderButton(
								'CREATED',
								'createdAt',
								'desc',
								onCreatedAtSortClick
							)}
						</th>
						{showDeleted && <th>DELETED</th>}
					</tr>
				</thead>
				<tbody>
					{data?.tasks.data.map((task) => (
						<tr
							key={task.id}
							onClick={() => {
								onTaskClick?.(task);
							}}
						>
							<td>{task.id}</td>
							<td>{task.status}</td>
							<td>{task.alertType && task.status === 'open' ? 'YES' : 'NO'}</td>
							<td>{task.taskType}</td>
							<td>{task.name || 'None'}</td>
							<td>{task.userId}</td>
							<td>{new Date(task.createdAt).toLocaleString()}</td>
							{showDeleted && (
								<td>
									{task.deletedAt
										? new Date(task.deletedAt).toLocaleString()
										: 'N/A'}
								</td>
							)}
						</tr>
					))}
				</tbody>
			</HTMLTable>

			<br />

			<div className={styles.paginationContainer}>
				<Text>{data ? data.tasks.total : ''} results</Text>

				<PaginationButtonsView
					page={
						data
							? {
									limit: data.tasks.limit,
									offset: data.tasks.offset,
									hasMore: data.tasks.hasMore
							  }
							: null
					}
					onPreviousPress={onPreviousPress}
					onNextPress={onNextPress}
				/>
			</div>
			<br />
		</div>
	);
}
