import { ReactElement, useEffect, useState } from 'react';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { DeleteUserSessionForm } from '..';

export type DeleteUserSessionProps = {
	userId: string;
	sessionId: string;
	onSuccess?: () => void;
};
export function DeleteUserSession(props: DeleteUserSessionProps): ReactElement {
	const { userId, sessionId, onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = () => {
		setLoading(true);
		setError('');
		openApi.users
			.deleteSession(userId, sessionId)
			.then(() => {
				AppToaster.show({ message: 'User session deleted', intent: 'success' });
				onSuccess?.();
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: () => {
						setError('Unknown error');
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return <DeleteUserSessionForm loading={loading} onSubmit={onSubmit} />;
}
