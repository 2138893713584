import { ReactElement, ReactNode } from 'react';
import styles from './page-section.module.scss';
import { Button, H4, Intent } from '@blueprintjs/core';

type PageSectionProps = {
	title: string;
	createButton?: boolean;
	children?: ReactNode;
	onCreate?: () => void;
};

export function PageSection(props: PageSectionProps): ReactElement {
	const { title, createButton, children } = props;
	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<H4>{title}</H4>
				{createButton && (
					<Button icon={'add'} intent={Intent.PRIMARY}>
						{`Create`}
					</Button>
				)}
			</div>
			{children}
		</div>
	);
}
