import { H1 } from '@blueprintjs/core';
import { ReactElement } from 'react';

export function NotFoundPage(): ReactElement {
	return (
		<div>
			<H1>404 Not Found</H1>
		</div>
	);
}
