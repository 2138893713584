import { ReactElement, useEffect, useState, useRef } from 'react';
import {
	Admin,
	AdminsResponseData
} from '@techshift/callie-private-api-typescript';
import styles from './admins-table.module.scss';
import { useOpenApi } from '../../../core/providers';
import { HTMLTable } from '@blueprintjs/core';
import { TableFooter } from '../../../ui/components';

type AdminsTableProps = {
	initialLimit?: number;
	initialOffset?: number;
	onOffsetChange?: (offset: number) => void;
	onAdminClick?: (admin: Admin) => void;
	showDeleted?: boolean;
};

export function AdminsTable(props: AdminsTableProps): ReactElement {
	const {
		initialLimit,
		initialOffset,
		onOffsetChange,
		onAdminClick,
		showDeleted = false
	} = props;

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);

	const [adminsResponse, setAdminsResponse] =
		useState<AdminsResponseData | null>(null);

	const [limit] = useState<number>(initialLimit || 25);
	const [offset, setOffset] = useState<number>(initialOffset || 0);

	useEffect(() => {
		setAdminsResponse(null);
		openApiRef.current.admins
			.getAdmins({ limit, offset, deleted: showDeleted })
			.then((response) => {
				setAdminsResponse(response.data.data);
			});
	}, [limit, offset, showDeleted]);

	useEffect(() => {
		onOffsetChange?.(offset);
	}, [onOffsetChange, offset]);

	return (
		<div className={styles.container}>
			<HTMLTable striped interactive>
				<thead>
					<tr>
						<th>FIRST NAME</th>
						<th>LAST NAME</th>
						<th>EMAIL</th>
						<th>ID</th>
						<th>CREATED</th>
						{showDeleted && <th>DELETED</th>}
					</tr>
				</thead>
				<tbody>
					{adminsResponse?.admins.data.map((admin) => (
						<tr onClick={() => onAdminClick?.(admin)}>
							<td>{admin.firstName}</td>
							<td>{admin.lastName}</td>
							<td>{admin.email}</td>
							<td>{admin.id}</td>
							<td>{new Date(admin.createdAt).toLocaleString()}</td>
							{showDeleted && (
								<td>
									{admin.deletedAt
										? new Date(admin.deletedAt).toLocaleString()
										: 'N/A'}
								</td>
							)}
						</tr>
					))}
				</tbody>
			</HTMLTable>

			<br />

			<TableFooter
				page={
					adminsResponse
						? {
								offset: adminsResponse.admins.offset,
								limit: adminsResponse.admins.limit,
								hasMore: adminsResponse.admins.hasMore,
								total: adminsResponse.admins.total
						  }
						: null
				}
				onSetOffset={setOffset}
			/>
			<br />
		</div>
	);
}
