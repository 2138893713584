import { ReactElement, useCallback } from 'react';
import styles from './admins-deleted.page.module.scss';
import { AdminsTable, CreateAdminButton } from '../../../admins/components';
import { ButtonGroup, H2 } from '@blueprintjs/core';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function AdminsDeletedPage(): ReactElement {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const onOffsetChange = useCallback(
		(offset: number) => {
			setSearchParams({ offset: offset.toString() });
		},
		[setSearchParams]
	);

	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<H2>Deleted admins</H2>
				<ButtonGroup>
					<CreateAdminButton />
				</ButtonGroup>
			</div>
			<AdminsTable
				initialOffset={Number(searchParams.get('offset')) || undefined}
				onOffsetChange={onOffsetChange}
				onAdminClick={(admin) => navigate(`/admins/${admin.id}`)}
				showDeleted
			/>
		</div>
	);
}
