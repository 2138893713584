import { ReactElement, useEffect, useState } from 'react';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { Guardian } from '@techshift/callie-private-api-typescript';
import {
	UpdateGuardianForm,
	UpdateGuardianFormData
} from './update-guardian-form';

export type UpdateGuardianProps = {
	userId: string;
	guardian: Guardian;
	onSuccess?: (user: Guardian) => void;
};
export function UpdateGuardian(props: UpdateGuardianProps): ReactElement {
	const { userId, guardian, onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = ({ name }: UpdateGuardianFormData) => {
		setLoading(true);
		setError('');
		openApi.users
			.updateGuardian(userId, guardian.phone, { name })
			.then((response) => {
				AppToaster.show({ message: 'Guardian updated', intent: 'success' });
				onSuccess?.(response.data.data.guardian);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'GUARDIAN_NOT_UNIQUE') {
							setError('A guardian with this phone number already exists');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return (
		<UpdateGuardianForm
			initialName={guardian.name}
			loading={loading}
			onSubmit={onSubmit}
		/>
	);
}
