import { ReactElement, useMemo, useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import dayjs from 'dayjs';
import styles from './map-marker.module.scss';
import { Text } from '../../../ui/components';

export type MapMarkerProps = {
	type: 'start' | 'finish';
	latitude: number;
	longitude: number;
	timestamp: string;
};

export function MapMarker(props: MapMarkerProps): ReactElement {
	const { type, latitude, longitude, timestamp } = props;

	const color = 'black';

	const text = useMemo(() => {
		if (type === 'start') {
			return 'Started here';
		} else if (type === 'finish') {
			return 'Last known location';
		} else {
			return '';
		}
	}, [type]);
	const time = useMemo(() => dayjs(timestamp).format('HH:mm'), [timestamp]);

	const [showPopup, setShowPopup] = useState<any>(null);

	return (
		<>
			<Marker
				longitude={longitude}
				latitude={latitude}
				anchor={'bottom'}
				onClick={(e) => {
					e.originalEvent.stopPropagation();
					setShowPopup(true);
				}}
			>
				<div className={styles.marker}>
					<svg
						version="1.1"
						id="Layer_1"
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						viewBox="0 0 512 512"
						style={{
							// @ts-ignore
							enableBackground: 'new 0 0 512 512'
						}}
					>
						<g>
							<path
								fill={color}
								d="M256,512C136.5,356.1,76.8,245.2,76.8,179.2C76.8,80.2,157,0,256,0s179.2,80.2,179.2,179.2
		C435.2,245.2,375.5,356.1,256,512z M256,281.6c56.5,0,102.4-45.9,102.4-102.4S312.5,76.8,256,76.8s-102.4,45.8-102.4,102.4
		S199.4,281.6,256,281.6z"
							/>
						</g>
					</svg>
				</div>
			</Marker>
			{showPopup && (
				<Popup
					anchor="top"
					longitude={longitude}
					latitude={latitude}
					onClose={() => setShowPopup(false)}
					closeButton={false}
					closeOnClick={true}
					className={styles.popup}
				>
					<div className={styles.popupContent}>
						<Text color={'white'} small>
							{text}
						</Text>
						<Text color={'white'} small>
							{time}
						</Text>
					</div>
				</Popup>
			)}
		</>
	);
}
