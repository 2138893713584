import { useId } from 'react';
import { Button, FormGroup, H1, InputGroup, Intent } from '@blueprintjs/core';
import { useForm, Controller } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

export type LoginFormData = {
	email: string;
	password: string;
};

const schema = Joi.object({
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required(),

	password: Joi.string().max(64).required()
});

export type LoginFormProps = {
	onSubmit?: (data: LoginFormData) => void;
	loading?: boolean;
};

export function LoginForm(props: LoginFormProps) {
	const {
		onSubmit = () => {
			return;
		},
		loading
	} = props;

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<LoginFormData>({
		resolver: joiResolver(schema)
	});

	const id = useId();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<H1 className="bp4-heading">Callie Admin Login</H1>
			<FormGroup
				label="Email"
				labelFor={'email' + id}
				labelInfo="(required)"
				subLabel={errors.email?.message}
				intent={!errors.email?.message ? Intent.NONE : Intent.DANGER}
			>
				<Controller
					name="email"
					control={control}
					render={({ field }) => (
						<InputGroup
							id={'email' + id}
							placeholder="john@example.com"
							intent={!errors.email?.message ? Intent.NONE : Intent.DANGER}
							{...field}
						/>
					)}
				/>
			</FormGroup>

			<FormGroup
				label="Password"
				labelFor={'password' + id}
				labelInfo="(required)"
				subLabel={errors.password?.message}
				intent={!errors.password?.message ? Intent.NONE : Intent.DANGER}
			>
				<Controller
					name="password"
					control={control}
					render={({ field }) => (
						<InputGroup
							id={'password' + id}
							type="password"
							intent={!errors.password?.message ? Intent.NONE : Intent.DANGER}
							{...field}
						/>
					)}
				/>
			</FormGroup>

			<Button intent={Intent.PRIMARY} type={'submit'} loading={loading}>
				Login
			</Button>
		</form>
	);
}
