import { ReactElement } from 'react';
import styles from './table-footer.module.scss';
import { PaginationButtons } from '../pagination-buttons';
import { Text } from '../../../ui/components/text';

type TableFooterProps = {
	page: {
		limit: number;
		offset: number;
		hasMore: boolean;
		total: number;
	} | null;
	onSetOffset: (offset: number) => void;
};

export function TableFooter(props: TableFooterProps): ReactElement {
	const { page, onSetOffset } = props;

	return (
		<div className={styles.container}>
			<Text>{page ? page.total : ''} results</Text>

			<PaginationButtons page={page} onSetOffset={onSetOffset} />
		</div>
	);
}
