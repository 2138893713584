import { AnchorButton } from '@blueprintjs/core';
import { ReactElement } from 'react';
import { Text } from '..';

type SidebarDataItemProps = {
	title: string;
	text: string;
};
export function SidebarDataItem(props: SidebarDataItemProps): ReactElement {
	const { title, text } = props;

	return (
		<>
			<Text>{title}</Text>
			<br />
			<Text bold>{text}</Text>
		</>
	);
}

type ClickableSidebarDataItemProps = {
	title: string;
	text: string;
	onClick?: () => void;
};
export function ClickableSidebarDataItem(
	props: ClickableSidebarDataItemProps
): ReactElement {
	const { title, text, onClick } = props;

	return (
		<>
			<Text>{title}</Text>
			<br />
			<AnchorButton small text={text} onClick={onClick} />
		</>
	);
}
