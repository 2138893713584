/* eslint-disable import/no-webpack-loader-syntax */
import { ReactElement, useEffect, useRef } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
import mapboxgl from '!mapbox-gl';

// Fix from https://github.com/visgl/react-map-gl/issues/1266#issuecomment-753686953
mapboxgl.workerClass =
	require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';

export function Map(): ReactElement {
	const mapContainer = useRef<any>(null);
	const map = useRef<mapboxgl.Map | null>(null);

	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v11'
		});
	});

	return <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />;
}
