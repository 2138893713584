import { ReactElement } from 'react';
import styles from './task-sidebar.module.scss';
import { H2, H3 } from '@blueprintjs/core';
import { Task } from '@techshift/callie-private-api-typescript';
import {
	ClickableSidebarDataItem,
	SidebarDataItem,
	Text
} from '../../../ui/components';
import { CountdownTimer } from '../countdown';

type TaskSidebarProps = {
	task: Task;
	onUserIdClick?: (userId: string) => void;
};
export function TaskSidebar(props: TaskSidebarProps): ReactElement {
	const { task, onUserIdClick } = props;

	return (
		<div className={styles.container}>
			<H2>{task.name}</H2>
			<br />
			<br />
			<H3>Details</H3>

			<SidebarDataItem title="Task ID" text={task.id} />
			<br />
			<br />
			<ClickableSidebarDataItem
				title="User ID"
				text={task.userId}
				onClick={() => {
					onUserIdClick?.(task.userId);
				}}
			/>
			<br />
			<br />
			<SidebarDataItem title="Status" text={task.status} />
			<br />
			<br />
			<SidebarDataItem title="Type" text={task.taskType} />
			<br />
			<br />
			{/* Open and not alerted */}
			{task && task.status === 'open' && !task.alertType && task.timeoutAt && (
				<>
					<Text bold>
						{'Timeout at ('}
						<CountdownTimer timeoutAt={new Date(task.timeoutAt)} />
						{')'}
					</Text>
					<br />
					<Text muted>{new Date(task.timeoutAt).toLocaleString()}</Text>
					<br />
					<br />
				</>
			)}
			{task && task.status === 'open' && task.alertType && (
				<>
					<SidebarDataItem title="Alert type" text={task.alertType} />
					<br />
					<br />
				</>
			)}
			<SidebarDataItem
				title="Created at"
				text={new Date(task.createdAt).toLocaleString()}
			/>
			<br />
			<br />
			{task.status === 'closed' && task.closedAt && (
				<>
					<SidebarDataItem
						title="Closed at"
						text={new Date(task.closedAt).toLocaleString()}
					/>
					<br />
					<br />
				</>
			)}
			<SidebarDataItem
				title="Updated at"
				text={new Date(task.updatedAt).toLocaleString()}
			/>
			<br />
			<br />
			{task.deletedAt && (
				<>
					<SidebarDataItem
						title="Deleted at"
						text={new Date(task.deletedAt).toLocaleString()}
					/>

					<br />
					<br />
				</>
			)}
		</div>
	);
}
