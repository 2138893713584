import { ReactElement, useId } from 'react';
import { Button, FormGroup, H3, InputGroup, Intent } from '@blueprintjs/core';
import { useForm, Controller } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

export type UpdateArcOperatorFormData = {
	firstName: string;
	lastName: string;
	email: string;
};

const schema = Joi.object({
	firstName: Joi.string().max(64).required(),
	lastName: Joi.string().max(64).required(),
	email: Joi.string()
		.email({ tlds: { allow: false } })
		.required()
});

export type UpdateArcOperatorFormProps = {
	initialFirstName: string;
	initialLastName: string;
	initialEmail: string;
	onSubmit?: (data: UpdateArcOperatorFormData) => void;
	loading?: boolean;
};
export function UpdateArcOperatorForm(
	props: UpdateArcOperatorFormProps
): ReactElement {
	const {
		initialFirstName,
		initialLastName,
		initialEmail,
		onSubmit = () => {
			return;
		},
		loading
	} = props;

	const id = useId();

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<UpdateArcOperatorFormData>({
		resolver: joiResolver(schema)
	});

	return (
		<>
			<H3>Edit ARC operator</H3>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormGroup
					label="First name"
					labelFor={'firstName' + id}
					labelInfo="(required)"
					subLabel={errors.firstName?.message}
					intent={!errors.firstName?.message ? Intent.NONE : Intent.DANGER}
				>
					<Controller
						name="firstName"
						control={control}
						defaultValue={initialFirstName}
						render={({ field }) => (
							<InputGroup
								id={'firstName' + id}
								maxLength={64}
								intent={
									!errors.firstName?.message ? Intent.NONE : Intent.DANGER
								}
								{...field}
							/>
						)}
					/>
				</FormGroup>
				<FormGroup
					label="Last name"
					labelFor={'lastName' + id}
					labelInfo="(required)"
					subLabel={errors.lastName?.message}
					intent={!errors.lastName?.message ? Intent.NONE : Intent.DANGER}
				>
					<Controller
						name="lastName"
						control={control}
						defaultValue={initialLastName}
						render={({ field }) => (
							<InputGroup
								id={'lastName' + id}
								maxLength={64}
								intent={!errors.lastName?.message ? Intent.NONE : Intent.DANGER}
								{...field}
							/>
						)}
					/>
				</FormGroup>
				<FormGroup
					label="Email"
					labelFor={'email' + id}
					labelInfo="(required)"
					subLabel={errors.email?.message}
					intent={!errors.email?.message ? Intent.NONE : Intent.DANGER}
				>
					<Controller
						name="email"
						control={control}
						defaultValue={initialEmail}
						render={({ field }) => (
							<InputGroup
								id={'email' + id}
								intent={!errors.email?.message ? Intent.NONE : Intent.DANGER}
								{...field}
							/>
						)}
					/>
				</FormGroup>

				<Button intent={Intent.PRIMARY} type={'submit'} loading={loading}>
					Confirm
				</Button>
			</form>
		</>
	);
}
