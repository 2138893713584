import { ReactElement } from 'react';
import styles from './tasks-table-page-section.module.scss';
import { PageSection } from '../../../shared/components';
import { TasksTable } from './tasks-table';
import { Task } from '@techshift/callie-private-api-typescript';

type TasksPageSectionProps = {
	userId: string;
	onTaskClick?: (task: Task) => void;
};
export function TasksPageSection(props: TasksPageSectionProps): ReactElement {
	const { userId, onTaskClick } = props;

	return (
		<div className={styles.container}>
			<PageSection title={'Tasks'}>
				<TasksTable
					initialQuery={{ limit: 5, userId }}
					onTaskClick={onTaskClick}
				/>
			</PageSection>
		</div>
	);
}
