import { ReactElement } from 'react';
import styles from './admin-sidebar.module.scss';
import { H2, H3 } from '@blueprintjs/core';
import { Admin } from '@techshift/callie-private-api-typescript';
import { SidebarDataItem, Text } from '../../../ui/components';
import { DeleteAdminButton, RestoreAdminButton, UpdateAdminButton } from '..';

type AdminSidebarProps = {
	admin: Admin;
	onUpdateAdmin: (admin: Admin) => void;
	onDeleteAdmin: () => void;
};
export function AdminSidebar(props: AdminSidebarProps): ReactElement {
	const { admin, onUpdateAdmin, onDeleteAdmin } = props;

	return (
		<div className={styles.container}>
			<H2>{admin.firstName + ' ' + admin.lastName}</H2>
			<Text>{admin.email}</Text>
			<br />
			<br />
			<H3>Details</H3>
			<SidebarDataItem title="Admin ID" text={admin.id} />
			<br />
			<br />
			<SidebarDataItem title="First name" text={admin.firstName} />
			<br />
			<br />
			<SidebarDataItem title="Last name" text={admin.lastName} />
			<br />
			<br />
			<SidebarDataItem title="Email" text={admin.email} />
			<br />
			<br />
			<SidebarDataItem
				title="Created at"
				text={new Date(admin.createdAt).toLocaleString()}
			/>
			<br />
			<br />
			<SidebarDataItem
				title="Updated at"
				text={new Date(admin.updatedAt).toLocaleString()}
			/>
			<br />
			<br />
			{admin.deletedAt && (
				<>
					<SidebarDataItem
						title="Deleted at"
						text={new Date(admin.deletedAt).toLocaleString()}
					/>
					<br />
					<br />
				</>
			)}

			<UpdateAdminButton admin={admin} onSuccess={onUpdateAdmin} />
			<br />
			<br />
			{admin.deletedAt ? (
				<>
					<RestoreAdminButton admin={admin} onSuccess={onUpdateAdmin} />
					<br />
					<br />
					<DeleteAdminButton hard admin={admin} onSuccess={onDeleteAdmin} />
				</>
			) : (
				<DeleteAdminButton admin={admin} onSuccess={onDeleteAdmin} />
			)}
		</div>
	);
}
