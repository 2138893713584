import { ReactElement, useEffect, useState, useRef, useCallback } from 'react';
import { UserSessions } from '@techshift/callie-private-api-typescript';
import styles from './user-sessions-table.module.scss';
import { useOpenApi } from '../../../core/providers';
import {
	Button,
	HTMLTable,
	Icon,
	NonIdealState,
	Spinner,
	Menu,
	Intent
} from '@blueprintjs/core';
import { MenuItem2, Popover2 } from '@blueprintjs/popover2';
import { TableFooter } from '../../../ui/components';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { DeleteUserSessionDialog } from '../delete-user-session';

export type UserSessionsTableQuery = {
	userId?: string;
	limit?: number;
	offset?: number;
	sortOrder?: 'asc' | 'desc';
};

type UserSessionsTableProps = {
	initialQuery: UserSessionsTableQuery;
	onQueryChange?: (query: UserSessionsTableQuery) => void;
};

export function UserSessionsTable(props: UserSessionsTableProps): ReactElement {
	const { initialQuery, onQueryChange } = props;

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [userSessions, setUserSessions] = useState<UserSessions | null>(null);

	const [toDelete, setToDelete] = useState('');

	const [userId] = useState<string | undefined>(initialQuery.userId);
	const [limit] = useState<number | undefined>(initialQuery.limit);
	const [offset, setOffset] = useState<number | undefined>(initialQuery.offset);
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(
		initialQuery.sortOrder
	);

	const fetch = useCallback(() => {
		setUserSessions(null);
		setLoading(true);
		setError('');
		openApiRef.current.users
			.getSessions(userId || '', { limit, offset, sortOrder })
			.then((response) => {
				setUserSessions(response.data.data.sessions);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: () => {
						setError('Unknown error');
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, [userId, limit, offset, sortOrder]);

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	useEffect(() => {
		onQueryChange?.({
			userId,
			limit,
			offset,
			sortOrder
		});
	}, [onQueryChange, userId, limit, offset, sortOrder]);

	const renderButton = useCallback(
		(text: string) => {
			return (
				<button
					className={styles.headerButton}
					onClick={() => {
						setSortOrder((sortOrder) => (sortOrder === 'asc' ? 'desc' : 'asc'));
						setOffset(undefined);
					}}
				>
					{text}
					<Icon
						icon={sortOrder === 'asc' ? 'sort-asc' : 'sort-desc'}
						className={styles.iconSelected}
					/>
				</button>
			);
		},
		[sortOrder]
	);

	return (
		<>
			<div className={styles.container}>
				{loading && <NonIdealState icon={<Spinner />} title={'Loading'} />}
				{error && (
					<NonIdealState
						icon={'error'}
						title={'Error'}
						description={error}
						action={
							<Button outlined onClick={fetch}>
								Retry
							</Button>
						}
					/>
				)}
				{userSessions && (
					<>
						<HTMLTable striped interactive>
							<thead>
								<tr>
									<th>ID</th>
									<th>User ID</th>
									<th>User Agent</th>
									<th>{renderButton('Last used')}</th>
									<th>Logged in</th>
									<th />
								</tr>
							</thead>
							<tbody>
								{userSessions.data.map((userSession, index) => (
									<tr key={index}>
										<td>{userSession.id}</td>
										<td>{userSession.userId}</td>
										<td>{userSession.userAgentSimple}</td>
										<td>{new Date(userSession.lastUsed).toLocaleString()}</td>
										<td>{new Date(userSession.createdAt).toLocaleString()}</td>
										<td>
											<Popover2
												enforceFocus={false}
												placement="bottom-end"
												interactionKind={'click'}
												minimal
												content={
													<Menu>
														<MenuItem2
															icon="trash"
															text="Delete"
															intent={Intent.DANGER}
															onClick={() => {
																setToDelete(userSession.id);
															}}
														/>
													</Menu>
												}
												renderTarget={({ isOpen, ref, ...p }) => (
													<Button
														{...p}
														active={isOpen}
														elementRef={ref as any}
														minimal
														small
														icon="more"
													/>
												)}
											/>
										</td>
									</tr>
								))}
							</tbody>
						</HTMLTable>

						<br />

						<TableFooter
							page={
								userSessions
									? {
											offset: userSessions.offset,
											limit: userSessions.limit,
											hasMore: userSessions.hasMore,
											total: userSessions.total
									  }
									: null
							}
							onSetOffset={setOffset}
						/>
						<br />
					</>
				)}
			</div>
			<DeleteUserSessionDialog
				userId={userId || ''}
				sessionId={toDelete}
				isOpen={!!toDelete}
				onClose={() => setToDelete('')}
				onSuccess={() => {
					setUserSessions((prev) => {
						if (prev === null) {
							return null;
						}

						return {
							...prev,
							data: prev.data.filter((d) => d.id !== toDelete),
							total: prev.total - 1
						};
					});
					setToDelete('');
				}}
			/>
		</>
	);
}
