import { Location } from '@techshift/callie-private-api-typescript';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { getTimeAgo } from '../../../core/util/time';
import { useInterval } from '../../../util/use-interval';
import { Text } from '../../../ui/components';

type ReverseGeocodeProps = {
	location: Location;
	latestUpdate: Date | null;
};

const accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';

function preprocessCoord(coord: number): number {
	// 4 decimal places to an accuracy of 11.1m (https://en.wikipedia.org/wiki/Decimal_degrees)
	return parseFloat(coord.toFixed(4));
}

export function ReverseGeocode(props: ReverseGeocodeProps): ReactElement {
	const { location, latestUpdate } = props;

	const [latitude, setLatitude] = useState<number>(
		preprocessCoord(location.latitude)
	);
	const [longitude, setLongitude] = useState<number>(
		preprocessCoord(location.longitude)
	);
	useEffect(() => {
		setLatitude(preprocessCoord(location.latitude));
		setLongitude(preprocessCoord(location.longitude));
	}, [location.latitude, location.longitude]);

	const [address, setAddress] = useState<string | null>(null);

	useEffect(() => {
		function loadAddress() {
			fetch(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`
			)
				.then((response) => response.json())
				.then((data) => {
					if (data.features.length > 0) {
						setAddress(data.features[0].place_name);
					}
				})
				.catch(() => {
					// wait 5 seconds
					setTimeout(loadAddress, 5000);
				});
		}

		loadAddress();
	}, [latitude, longitude]);

	const [refreshedAgo, setRefreshedAgo] = useState<string>('');
	const calculateTime = useCallback((time: Date) => {
		setRefreshedAgo(getTimeAgo(time));
	}, []);
	useEffect(() => {
		if (latestUpdate) {
			calculateTime(latestUpdate);
		}
	}, [latestUpdate, calculateTime]);
	useInterval(
		() => {
			if (latestUpdate) {
				calculateTime(latestUpdate);
			}
		},
		1000,
		[latestUpdate, calculateTime]
	);

	return (
		<>
			{address === null && <Text color="white">Loading address</Text>}
			{address !== null && (
				<div
					style={{
						display: 'flex',
						flex: 1,
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'space-between'
					}}
				>
					<Text color="white" muted textAlign="center">
						{`Approx: ${address}`}
					</Text>
					{refreshedAgo && (
						<>
							<Text color="white" muted small textAlign="center">
								{'Updated ' + refreshedAgo}
							</Text>
						</>
					)}
				</div>
			)}
		</>
	);
}
