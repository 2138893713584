import { ReactElement, useState } from 'react';
import { Button, Classes, Dialog } from '@blueprintjs/core';
import { RestoreArcOperator } from '..';
import { ArcOperator } from '@techshift/callie-private-api-typescript';

export type RestoreArcOperatorButtonProps = {
	operator: ArcOperator;
	onSuccess?: (operator: ArcOperator) => void;
};
export function RestoreArcOperatorButton(
	props: RestoreArcOperatorButtonProps
): ReactElement {
	const { operator, onSuccess } = props;

	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Button
				onClick={() => {
					setIsOpen(true);
				}}
			>
				Restore
			</Button>
			<Dialog isOpen={isOpen}>
				<div className={Classes.DIALOG_BODY}>
					<RestoreArcOperator
						operator={operator}
						onSuccess={(operator) => {
							onSuccess?.(operator);
							setIsOpen(false);
						}}
					/>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							onClick={() => {
								setIsOpen(false);
							}}
						>
							Close
						</Button>
					</div>
				</div>
			</Dialog>
		</>
	);
}
