import { ReactElement } from 'react';
import { Button, H3, Intent } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import { Text } from '../../../ui/components';

type DeleteUserSessionFormData = {};

export type DeleteUserSessionFormProps = {
	onSubmit?: () => void;
	loading?: boolean;
};
export function DeleteUserSessionForm(
	props: DeleteUserSessionFormProps
): ReactElement {
	const {
		onSubmit = () => {
			return;
		},
		loading
	} = props;

	const { handleSubmit } = useForm<DeleteUserSessionFormData>({});

	return (
		<>
			<H3>Delete user session</H3>
			<Text>Are you sure you want to delete the user sesssion?</Text>
			<br />
			<br />
			<Text>The user will be force logged out in up-to 5 minutes.</Text>
			<br />
			<br />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Button intent={Intent.DANGER} type={'submit'} loading={loading}>
					Delete
				</Button>
			</form>
		</>
	);
}
