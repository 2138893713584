import { ReactElement, useId } from 'react';
import { Button, FormGroup, H3, InputGroup, Intent } from '@blueprintjs/core';
import { useForm, Controller } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Text } from '../../../ui/components';

type DeleteUserFormData = {
	id: string;
};

export type DeleteUserFormProps = {
	id: string;
	hard?: boolean;
	onSubmit?: () => void;
	loading?: boolean;
};
export function DeleteUserForm(props: DeleteUserFormProps): ReactElement {
	const {
		id,
		hard,
		onSubmit = () => {
			return;
		},
		loading
	} = props;

	const formId = useId();

	const schema = Joi.object({
		id: Joi.string().equal(id).required()
	});

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<DeleteUserFormData>({
		resolver: joiResolver(schema)
	});

	return (
		<>
			<H3>{`${hard ? 'Hard' : 'Soft'} delete user`}</H3>
			<Text>Enter the ID of the user to confirm</Text>
			<br />
			<br />
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormGroup
					label="User id"
					labelFor={'id' + formId}
					labelInfo="(required)"
					subLabel={errors.id?.message}
					intent={!errors.id?.message ? Intent.NONE : Intent.DANGER}
				>
					<Controller
						name="id"
						control={control}
						render={({ field }) => (
							<InputGroup
								id={'id' + formId}
								intent={!errors.id?.message ? Intent.NONE : Intent.DANGER}
								{...field}
							/>
						)}
					/>
				</FormGroup>

				<Button intent={Intent.DANGER} type={'submit'} loading={loading}>
					Delete
				</Button>
			</form>
		</>
	);
}
