import { Battery, Location } from '@techshift/callie-private-api-typescript';
import { ReactElement, useMemo } from 'react';
import styles from './location-bottom-bar.module.scss';
import pin from './pin.svg';
import { ReverseGeocode } from './reverse-geocode';
import { Text } from '../../../ui/components';

export type LocationBottomBarProps = {
	locations: Location[];
	battery: Battery | null;
};

export function LocationBottomBar(props: LocationBottomBarProps): ReactElement {
	const { locations, battery } = props;

	const latestUpdate = useMemo<Date | null>(() => {
		const latestLocationDate =
			locations.length > 0 ? new Date(locations[0].timestamp) : null;
		const latestBatteryDate = battery ? new Date(battery.timestamp) : null;

		if (latestLocationDate && latestBatteryDate) {
			return latestLocationDate > latestBatteryDate
				? latestLocationDate
				: latestBatteryDate;
		} else if (latestLocationDate) {
			return latestLocationDate;
		} else if (latestBatteryDate) {
			return latestBatteryDate;
		} else {
			return null;
		}
	}, [locations, battery]);

	return (
		<div className={styles.container}>
			<div className={styles.pinImageContainer}>
				<img src={pin} alt="Locate" className={styles.pinImage} />
			</div>
			<div className={styles.contentContainer}>
				{locations.length === 0 && (
					<Text color="white" muted>
						No location data
					</Text>
				)}
				{locations.length > 0 && (
					<ReverseGeocode location={locations[0]} latestUpdate={latestUpdate} />
				)}
			</div>
		</div>
	);
}
