import { ReactElement, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, NonIdealState, Spinner } from '@blueprintjs/core';
import { useOpenApi } from '../../../core/providers';
import { ArcOperator } from '@techshift/callie-private-api-typescript';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { ArcOperatorSidebar } from '../../../arc/components';

export function ArcOperatorIdPage(): ReactElement {
	const { operatorId } = useParams();
	const navigate = useNavigate();

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [operator, setOperator] = useState<ArcOperator | null>(null);

	const fetch = useCallback((id: string) => {
		setOperator(null);
		setLoading(true);
		setError('');
		openApi.arc
			.getArcOperator(id)
			.then((response) => {
				setOperator(response.data.data.operator);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'OPERATOR_NOT_FOUND') {
							setError('ARC operator not found');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	useEffect(() => {
		if (!operatorId) {
			return;
		}

		fetch(operatorId);
	}, [operatorId, fetch]);

	return (
		<div style={{ flex: 1, paddingTop: 25, paddingLeft: 25 }}>
			{loading && <NonIdealState icon={<Spinner />} title={'Loading'} />}
			{error && (
				<NonIdealState
					icon={'error'}
					title={'Error'}
					description={error}
					action={
						<Button
							outlined
							onClick={() => {
								if (operatorId) {
									fetch(operatorId);
								}
							}}
						>
							Retry
						</Button>
					}
				/>
			)}

			{operator && (
				<ArcOperatorSidebar
					operator={operator}
					onUpdateArcOperator={setOperator}
					onDeleteArcOperator={() => {
						navigate(`/arc`);
					}}
				/>
			)}
		</div>
	);
}
