import { ReactElement, useEffect, useState } from 'react';
import {
	CreateArcOperatorForm,
	CreateArcOperatorFormData
} from '../create-arc-operator-form';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { CreateArcOperatorResponseData } from '@techshift/callie-private-api-typescript';
import { CreatedArcOperator } from '../created-arc-operator';

export type CreateArcOperatorProps = {
	onSuccess?: () => void;
};
export function CreateArcOperator(props: CreateArcOperatorProps): ReactElement {
	const { onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [createdArcOperator, setCreatedArcOperator] =
		useState<CreateArcOperatorResponseData | null>(null);

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = ({
		firstName,
		lastName,
		email
	}: CreateArcOperatorFormData) => {
		setLoading(true);
		setError('');
		openApi.arc
			.createArcOperator({ firstName, lastName, email })
			.then((response) => {
				AppToaster.show({ message: 'ARC operator created', intent: 'success' });
				setCreatedArcOperator(response.data.data);
				onSuccess?.();
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'OPERATOR_ALREADY_EXISTS') {
							setError('ARC operator with this email already exists');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return (
		<>
			{createdArcOperator === null ? (
				<CreateArcOperatorForm loading={loading} onSubmit={onSubmit} />
			) : (
				<CreatedArcOperator
					operator={createdArcOperator.operator}
					password={createdArcOperator.password}
					onCreateAnother={() => {
						setCreatedArcOperator(null);
					}}
				/>
			)}
		</>
	);
}
