import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './sub-main.page.module.scss';
import { H3, Menu, MenuItem } from '@blueprintjs/core';
import { NavLink } from 'react-router-dom';

export type SubMainPageProps = {
	title: string;
	items: {
		text: string;
		link: string;
		onClick?: () => void;
	}[];
};
export function SubMainPage(props: SubMainPageProps): ReactElement {
	const { title, items } = props;

	return (
		<div className={styles.container}>
			<div className={styles.sidebar}>
				<Menu>
					<H3>{title}</H3>
					{items.map((item, index) => (
						<NavLink key={index} to={item.link} end>
							{({ isActive }) => (
								<MenuItem
									text={item.text}
									onClick={item.onClick}
									selected={isActive}
								/>
							)}
						</NavLink>
					))}
				</Menu>
			</div>
			<div className={styles.outletContainer}>
				<Outlet />
			</div>
		</div>
	);
}
