import { ReactElement } from 'react';
import styles from './main.page.module.scss';
import { Outlet, NavLink } from 'react-router-dom';
import { Alignment, Button, Intent, Menu, Navbar } from '@blueprintjs/core';
import { useNullableTokens } from '@techshift/react-core';
import { MenuItem2, Popover2 } from '@blueprintjs/popover2';

export function MainPage(): ReactElement {
	const { deleteTokens } = useNullableTokens();

	return (
		<div className={styles.container}>
			<Navbar fixedToTop>
				<Navbar.Group align={Alignment.LEFT}>
					<div style={{ width: 50 }} />
					<NavLink to="/home">
						{({ isActive }) => (
							<Button className="bp4-minimal" text="Home" active={isActive} />
						)}
					</NavLink>
					<NavLink to="/admins">
						{({ isActive }) => (
							<Button className="bp4-minimal" text="Admins" active={isActive} />
						)}
					</NavLink>
					<NavLink to="/arc">
						{({ isActive }) => (
							<Button className="bp4-minimal" text="ARC" active={isActive} />
						)}
					</NavLink>
					<NavLink to="/users">
						{({ isActive }) => (
							<Button className="bp4-minimal" text="Users" active={isActive} />
						)}
					</NavLink>
					<NavLink to="/tasks">
						{({ isActive }) => (
							<Button className="bp4-minimal" text="Tasks" active={isActive} />
						)}
					</NavLink>
				</Navbar.Group>

				<Navbar.Group align={Alignment.RIGHT}>
					<Popover2
						enforceFocus={false}
						placement="bottom-end"
						interactionKind={'click'}
						minimal
						content={
							<Menu>
								<MenuItem2
									icon="log-out"
									onClick={deleteTokens}
									text="Logout"
									intent={Intent.DANGER}
								/>
							</Menu>
						}
						renderTarget={({ isOpen, ref, ...p }) => (
							<Button
								{...p}
								active={isOpen}
								elementRef={ref as any}
								minimal
								icon="cog"
							/>
						)}
					/>

					<div style={{ width: 50 }} />
				</Navbar.Group>
			</Navbar>

			<Outlet />
		</div>
	);
}
