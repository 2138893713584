type Trans = {
	january: string;
	february: string;
	march: string;
	april: string;
	may: string;
	june: string;
	july: string;
	august: string;
	september: string;
	october: string;
	november: string;
	december: string;
	at: string;
	now: string;
	today: string;
	yesterday: string;
	seconds_ago: string;
	about_a_minute_ago: string;
	minutes_ago: string;
};
const t: Trans = {
	january: 'January',
	february: 'February',
	march: 'March',
	april: 'April',
	may: 'May',
	june: 'June',
	july: 'July',
	august: 'August',
	september: 'September',
	october: 'October',
	november: 'November',
	december: 'December',
	at: 'at',
	now: 'now',
	today: 'Today',
	yesterday: 'Yesterday',
	seconds_ago: 'seconds ago',
	about_a_minute_ago: 'about a minute ago',
	minutes_ago: 'minutes ago'
};
export function getTimeAgo(date: Date) {
	const MONTH_NAMES = [
		t['january'],
		t['february'],
		t['march'],
		t['april'],
		t['may'],
		t['june'],
		t['july'],
		t['august'],
		t['september'],
		t['october'],
		t['november'],
		t['december']
	];

	function getFormattedDate(
		date: Date,
		prefomattedDate = '',
		hideYear = false
	) {
		const day = date.getDate();
		const month = MONTH_NAMES[date.getMonth()];
		const year = date.getFullYear();
		const hours = date.getHours();
		let minutes: number | string = date.getMinutes();

		if (minutes < 10) {
			// Adding leading zero to minutes
			minutes = `0${minutes}`;
		}

		if (prefomattedDate) {
			// Today at 10:20
			// Yesterday at 10:20
			return `${prefomattedDate} ${t['at']} ${hours}:${minutes}`;
		}

		if (hideYear) {
			// 10. January at 10:20
			return `${day}. ${month} ${t['at']} ${hours}:${minutes}`;
		}

		// 10. January 2017. at 10:20
		return `${day}. ${month} ${year}. ${t['at']} ${hours}:${minutes}`;
	}

	const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
	const today = new Date();
	const yesterday = new Date(today.getTime() - DAY_IN_MS);
	const seconds = Math.round((today.getTime() - date.getTime()) / 1000);
	const minutes = Math.round(seconds / 60);
	const isToday = today.toDateString() === date.toDateString();
	const isYesterday = yesterday.toDateString() === date.toDateString();
	const isThisYear = today.getFullYear() === date.getFullYear();

	if (seconds < 5) {
		return t['now'];
	} else if (seconds < 60) {
		return `${seconds} ${t['seconds_ago']}`;
	} else if (seconds < 90) {
		return t['about_a_minute_ago'];
	} else if (minutes < 60) {
		return `${minutes} ${t['minutes_ago']}`;
	} else if (isToday) {
		return getFormattedDate(date, t['today']); // Today at 10:20
	} else if (isYesterday) {
		return getFormattedDate(date, t['yesterday']); // Yesterday at 10:20
	} else if (isThisYear) {
		return getFormattedDate(date, '', true); // 10. January at 10:20
	}

	return getFormattedDate(date); // 10. January 2017. at 10:20
}

export function getLogTime(date: Date) {
	const MONTH_NAMES = [
		t['january'],
		t['february'],
		t['march'],
		t['april'],
		t['may'],
		t['june'],
		t['july'],
		t['august'],
		t['september'],
		t['october'],
		t['november'],
		t['december']
	];

	const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
	const today = new Date();
	const yesterday = new Date(today.getTime() - DAY_IN_MS);
	const isToday = today.toDateString() === date.toDateString();
	const isYesterday = yesterday.toDateString() === date.toDateString();
	const isThisYear = today.getFullYear() === date.getFullYear();

	const _year = date.getFullYear();
	const _day = date.getDate();
	const _month = MONTH_NAMES[date.getMonth()];
	const _hours = date.getHours();
	let _minutes: number | string = date.getMinutes();
	if (_minutes < 10) {
		// Adding leading zero to minutes
		_minutes = `0${_minutes}`;
	}

	if (isToday) {
		return `${_hours}:${_minutes}`; // 10:20
	} else if (isYesterday) {
		return `${_hours}:${_minutes} - ${t['yesterday']}`; // 10:20 - Yesterday
	} else if (isThisYear) {
		return `${_hours}:${_minutes} - ${_day}. ${_month}`; // 10:20 - 10. January
	} else {
		return `${_hours}:${_minutes} - ${_day}. ${_month} ${_year}`; // 10:20 - 10. January 2017.
	}
}
