import { ReactElement } from 'react';
import { Button, H3 } from '@blueprintjs/core';
import { Admin } from '@techshift/callie-private-api-typescript';
import { Text } from '../../../ui/components';

export type CreatedAdminProps = {
	admin: Admin;
	password: string;
	onCreateAnother: () => void;
};
export function CreatedAdmin(props: CreatedAdminProps): ReactElement {
	const { admin, password, onCreateAnother } = props;
	return (
		<>
			<H3>Created admin</H3>
			<Text>
				A password has been genreated for this admin. Please copy and store this
				password now as it will not be shown again.
			</Text>

			<br />
			<br />

			<Text bold>First Name</Text>
			<br />
			<Text>{admin.firstName}</Text>

			<br />
			<br />

			<Text bold>Last Name</Text>
			<br />
			<Text>{admin.lastName}</Text>

			<br />
			<br />

			<Text bold>Email</Text>
			<br />
			<Text>{admin.email}</Text>

			<br />
			<br />

			<Text bold>Password</Text>
			<br />
			<Text>{password}</Text>

			<br />
			<br />
			<Button onClick={onCreateAnother}>Create another</Button>
		</>
	);
}
