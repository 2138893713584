import { ReactElement, useCallback } from 'react';
import styles from './tasks-open.page.module.scss';
import { H2 } from '@blueprintjs/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TasksTable, TasksTableQuery } from '../../../tasks/components';

export function TasksOpenPage(): ReactElement {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const onQueryChange = useCallback(
		(query: TasksTableQuery) => {
			const searchParams: any = {};
			if (query.offset !== undefined) {
				searchParams.offset = query.offset.toString();
			}
			if (query.sortBy !== undefined) {
				searchParams.sortBy = query.sortBy;
			}
			if (query.sortOrder !== undefined) {
				searchParams.sortOrder = query.sortOrder;
			}
			setSearchParams(searchParams);
		},
		[setSearchParams]
	);

	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<H2>Open tasks</H2>
			</div>
			<TasksTable
				initialQuery={{
					offset: Number(searchParams.get('offset')) || undefined,
					sortBy: (searchParams.get('sortBy') as any) || undefined,
					sortOrder: (searchParams.get('sortOrder') as any) || undefined,
					status: 'open'
				}}
				onQueryChange={onQueryChange}
				onTaskClick={(task) => navigate(`/tasks/${task.id}`)}
			/>
		</div>
	);
}
