import { ReactElement } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';

type PaginationButtonsViewProps = {
	page: {
		limit: number;
		offset: number;
		hasMore: boolean;
	} | null;
	onPreviousPress: () => void;
	onNextPress: () => void;
};

export function PaginationButtonsView(
	props: PaginationButtonsViewProps
): ReactElement {
	const { page, onPreviousPress, onNextPress } = props;

	return (
		<ButtonGroup>
			<Button
				disabled={page ? page.offset === 0 : false}
				onClick={onPreviousPress}
			>
				Previous
			</Button>
			<Button disabled={page ? !page.hasMore : false} onClick={onNextPress}>
				Next
			</Button>
		</ButtonGroup>
	);
}
