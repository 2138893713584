import { ReactElement, useEffect, useState, useRef, useCallback } from 'react';
import {
	User,
	UsersResponseData
} from '@techshift/callie-private-api-typescript';
import styles from './users-table.module.scss';
import { useOpenApi } from '../../../core/providers';
import { HTMLTable, Icon } from '@blueprintjs/core';
import { TableFooter } from '../../../ui/components';

export type UsersTableQuery = {
	offset?: number;
	sortBy?: 'firstName' | 'lastName' | 'email' | 'createdAt';
	sortOrder?: 'asc' | 'desc';
};

type UsersTableProps = {
	initialQuery: UsersTableQuery;
	onQueryChange?: (query: UsersTableQuery) => void;
	onUserClick?: (user: User) => void;
	showDeleted?: boolean;
};

export function UsersTable(props: UsersTableProps): ReactElement {
	const { initialQuery, onQueryChange, onUserClick, showDeleted } = props;

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);

	const [usersResponse, setUsersResponse] = useState<UsersResponseData | null>(
		null
	);

	const [offset, setOffset] = useState<number | undefined>(initialQuery.offset);
	const [sortBy, setSortBy] = useState<
		'firstName' | 'lastName' | 'email' | 'createdAt' | undefined
	>(initialQuery.sortBy);
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(
		initialQuery.sortOrder
	);

	useEffect(() => {
		setUsersResponse(null);
		openApiRef.current.users
			.getUsers({ limit: 20, offset, sortBy, sortOrder, deleted: showDeleted })
			.then((response) => {
				setUsersResponse(response.data.data);
			});
	}, [offset, sortBy, sortOrder, showDeleted]);

	useEffect(() => {
		onQueryChange?.({
			offset,
			sortBy,
			sortOrder
		});
	}, [onQueryChange, offset, sortBy, sortOrder]);

	const renderButton = useCallback(
		(
			text: string,
			thisSortBy: 'firstName' | 'lastName' | 'email' | 'createdAt' | undefined,
			defaultSortOrder: 'asc' | 'desc'
		) => {
			return (
				<button
					className={styles.headerButton}
					onClick={() => {
						if (sortBy !== thisSortBy) {
							setSortOrder(defaultSortOrder);
						} else {
							setSortOrder((sortOrder) =>
								sortOrder === 'asc' ? 'desc' : 'asc'
							);
						}
						setSortBy(thisSortBy);
						setOffset(undefined);
					}}
				>
					{text}
					{sortBy === thisSortBy && (
						<Icon
							icon={sortOrder === 'asc' ? 'sort-asc' : 'sort-desc'}
							className={styles.iconSelected}
						/>
					)}
					{sortBy !== thisSortBy && (
						<Icon
							icon={defaultSortOrder === 'asc' ? 'sort-asc' : 'sort-desc'}
							className={styles.icon}
						/>
					)}
				</button>
			);
		},
		[sortBy, sortOrder]
	);

	return (
		<div className={styles.container}>
			<HTMLTable striped interactive>
				<thead>
					<tr>
						<th>{renderButton('FIRST NAME', 'firstName', 'asc')}</th>

						<th>{renderButton('LAST NAME', 'lastName', 'asc')}</th>
						<th>PHONE</th>
						<th>{renderButton('EMAIL', 'email', 'asc')}</th>
						<th>ID</th>
						<th>{renderButton('CREATED', 'createdAt', 'desc')}</th>
						{showDeleted && <th>DELETED</th>}
					</tr>
				</thead>
				<tbody>
					{usersResponse?.users.data.map((user) => (
						<tr
							key={user.id}
							onClick={() => {
								onUserClick?.(user);
							}}
						>
							<td>{user.firstName}</td>
							<td>{user.lastName}</td>
							<td>{user.phone || 'N/A'}</td>
							<td>{user.email || 'N/A'}</td>
							<td>{user.id}</td>
							<td>{new Date(user.createdAt).toLocaleString()}</td>
							{showDeleted && (
								<td>
									{user.deletedAt
										? new Date(user.deletedAt).toLocaleString()
										: 'N/A'}
								</td>
							)}
						</tr>
					))}
				</tbody>
			</HTMLTable>

			<br />

			<TableFooter
				page={
					usersResponse
						? {
								offset: usersResponse.users.offset,
								limit: usersResponse.users.limit,
								hasMore: usersResponse.users.hasMore,
								total: usersResponse.users.total
						  }
						: null
				}
				onSetOffset={setOffset}
			/>
			<br />
		</div>
	);
}
