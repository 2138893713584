import { ReactElement } from 'react';
import styles from './user-sidebar.module.scss';
import { H2, H3 } from '@blueprintjs/core';
import { User } from '@techshift/callie-private-api-typescript';
import { SidebarDataItem } from '../../../ui/components';
import { UpdateUserButton, DeleteUserButton, RestoreUserButton } from '..';

type UserSidebarProps = {
	user: User;
	onUpdateUser: (user: User) => void;
	onDeleteUser: () => void;
};
export function UserSidebar(props: UserSidebarProps): ReactElement {
	const { user, onUpdateUser, onDeleteUser } = props;

	return (
		<div className={styles.container}>
			<img
				className={styles.image}
				src={user.image}
				alt={user.firstName + ' ' + user.lastName + ' profile picture'}
			/>
			<br />

			<H2>{user.firstName + ' ' + user.lastName}</H2>
			<br />
			<br />
			<H3>Details</H3>
			<SidebarDataItem title="User ID" text={user.id} />
			<br />
			<br />
			<SidebarDataItem title="First name" text={user.firstName} />
			<br />
			<br />
			<SidebarDataItem title="Last name" text={user.lastName} />
			<br />
			<br />
			<SidebarDataItem title="Phone" text={user.phone || 'None'} />
			<br />
			<br />
			<SidebarDataItem title="Email" text={user.email || 'None'} />
			<br />
			<br />
			<SidebarDataItem title="PIN" text={user.pin || 'None'} />
			<br />
			<br />
			<SidebarDataItem
				title="Distress phrase"
				text={user.distressPhrase || 'None'}
			/>
			<br />
			<br />
			<SidebarDataItem
				title="Created at"
				text={new Date(user.createdAt).toLocaleString()}
			/>
			<br />
			<br />
			<SidebarDataItem
				title="Updated at"
				text={new Date(user.updatedAt).toLocaleString()}
			/>
			<br />
			<br />
			{user.deletedAt && (
				<>
					<SidebarDataItem
						title="Deleted at"
						text={new Date(user.deletedAt).toLocaleString()}
					/>
					<br />
					<br />
				</>
			)}
			<UpdateUserButton user={user} onSuccess={onUpdateUser} />
			<br />
			<br />
			{user.deletedAt ? (
				<>
					<RestoreUserButton user={user} onSuccess={onUpdateUser} />
					<br />
					<br />
					<DeleteUserButton hard user={user} onSuccess={onDeleteUser} />
				</>
			) : (
				<DeleteUserButton user={user} onSuccess={onDeleteUser} />
			)}
		</div>
	);
}
