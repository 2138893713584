import { ReactElement } from 'react';
import { TaskMap, TaskSidebar } from '../../../tasks/components';
import { UserDetailsPageSection } from '../../../users/components';
import { TaskLogsPageSection } from '../../../tasks/components/task-logs';
import { useTaskLogs, useTasks } from '../../../tasks/providers';
import { useNavigate } from 'react-router-dom';

export function _TaskIdLoadedPage(): ReactElement {
	const { task, user } = useTasks();
	const { taskLogs } = useTaskLogs();

	const navigate = useNavigate();

	return (
		<>
			<TaskSidebar
				task={task}
				onUserIdClick={(userId) => {
					navigate(`/users/${userId}`);
				}}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					flex: 1,
					paddingLeft: 20,
					paddingRight: 20
				}}
			>
				<div style={{ flex: 1 }}>
					<UserDetailsPageSection user={user} />
					<br />
					<TaskLogsPageSection taskLogs={taskLogs} user={user} />
				</div>
				<div style={{ flex: 1 }}>
					<TaskMap />
					{/* <Map /> */}
				</div>
			</div>
		</>
	);
}
