import data from './region-bounding-boxes.json';

export interface Location {
	latitude: number;
	longitude: number;
}

export interface BoundingBox {
	minX: number;
	maxX: number;
	minY: number;
	maxY: number;
}

export interface RegionBoundingBoxMap {
	[isoAlpha2: string]: RegionBoundingBox;
}

export interface RegionBoundingBox {
	sw: {
		lat: number;
		lon: number;
	};
	ne: {
		lat: number;
		lon: number;
	};
}

export function getLocaleRegionBoundingBox(): RegionBoundingBox {
	return regionBoundingBoxes['GB'];
}

export function locationsToBounds(locations: Location[]): RegionBoundingBox {
	const boundingBox = getBoundingBox(locations);
	return {
		ne: {
			lat: boundingBox.maxX,
			lon: boundingBox.maxY
		},
		sw: {
			lat: boundingBox.minX,
			lon: boundingBox.minY
		}
	};
}

export function getBoundingBox(points: Location[]): BoundingBox {
	let minX = 0,
		maxX = 0,
		minY = 0,
		maxY = 0;

	// init first point
	if (points.length > 0) {
		((point) => {
			minX = point.latitude;
			maxX = point.latitude;
			minY = point.longitude;
			maxY = point.longitude;
		})(points[0]);
	}

	// calculate rect
	points.forEach((point) => {
		minX = Math.min(minX, point.latitude);
		maxX = Math.max(maxX, point.latitude);
		minY = Math.min(minY, point.longitude);
		maxY = Math.max(maxY, point.longitude);
	});

	return { minX, maxX, minY, maxY };
}

const regionBoundingBoxes: RegionBoundingBoxMap = data;
