import { ReactElement } from 'react';
import styles from './task-logs-page-section.module.scss';
import { H4 } from '@blueprintjs/core';
import { TaskLog, User } from '@techshift/callie-private-api-typescript';
import { TaskLogsTable } from './task-logs-table';

type TaskLogsPageSectionProps = {
	taskLogs: TaskLog[];
	user: User;
};

export function TaskLogsPageSection(
	props: TaskLogsPageSectionProps
): ReactElement {
	const { taskLogs, user } = props;

	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<H4>Logs</H4>
			</div>

			<TaskLogsTable taskLogs={taskLogs} user={user} />
		</div>
	);
}
