import { useEffect, useRef } from 'react';

export function useInterval(
	callback: (id: NodeJS.Timeout) => void,
	delay: number,
	deps: any[] = []
): void {
	const savedCallback = useRef<(id: NodeJS.Timeout) => void>();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		const id: NodeJS.Timeout = setInterval(() => {
			savedCallback.current?.(id);
		}, delay);
		return () => clearInterval(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [delay, savedCallback, ...deps]);
}
