import { ReactElement } from 'react';
import styles from './app-loading-error.module.scss';
import { Button, H2, Intent } from '@blueprintjs/core';

type AppLoadingErrorProps = {
	onRetryClick: () => void;
};
export function AppLoadingError(props: AppLoadingErrorProps): ReactElement {
	const { onRetryClick } = props;
	return (
		<div className={styles.container}>
			<H2>Something wrong happened</H2>
			<Button intent={Intent.PRIMARY} onClick={onRetryClick}>
				Retry
			</Button>
		</div>
	);
}
