import { ReactElement, useEffect, useState } from 'react';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { ArcOperator } from '@techshift/callie-private-api-typescript';
import { RestoreArcOperatorForm } from '../restore-arc-operator-form';

export type RestoreArcOperatorProps = {
	operator: ArcOperator;
	onSuccess?: (operator: ArcOperator) => void;
};
export function RestoreArcOperator(
	props: RestoreArcOperatorProps
): ReactElement {
	const { operator, onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = () => {
		setLoading(true);
		setError('');
		openApi.arc
			.updateArcOperator(operator.id, { deletedAt: null })
			.then((response) => {
				AppToaster.show({
					message: 'Arc operator restored',
					intent: 'success'
				});
				onSuccess?.(response.data.data.operator);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'OPERATOR_NOT_FOUND') {
							setError('Cannot restore ARC operator as they do not exist');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return (
		<RestoreArcOperatorForm
			id={operator.id}
			loading={loading}
			onSubmit={onSubmit}
		/>
	);
}
