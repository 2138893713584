import { ReactElement, useState } from 'react';
import { Button, Classes, Dialog } from '@blueprintjs/core';
import { RestoreAdmin } from '..';
import { Admin } from '@techshift/callie-private-api-typescript';

export type RestoreAdminButtonProps = {
	admin: Admin;
	onSuccess?: (admin: Admin) => void;
};
export function RestoreAdminButton(
	props: RestoreAdminButtonProps
): ReactElement {
	const { admin, onSuccess } = props;

	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Button
				onClick={() => {
					setIsOpen(true);
				}}
			>
				Restore
			</Button>
			<Dialog isOpen={isOpen}>
				<div className={Classes.DIALOG_BODY}>
					<RestoreAdmin
						admin={admin}
						onSuccess={(admin) => {
							onSuccess?.(admin);
							setIsOpen(false);
						}}
					/>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							onClick={() => {
								setIsOpen(false);
							}}
						>
							Close
						</Button>
					</div>
				</div>
			</Dialog>
		</>
	);
}
