import { ReactElement, ReactNode } from 'react';

type Props = {
	children?: ReactNode;
	bold?: boolean;
	muted?: boolean;
	small?: boolean;
	large?: boolean;
	overflowEllipsis?: boolean;
	runningText?: boolean;
	disabled?: boolean;
	color?: string;
	textAlign?: 'left' | 'center' | 'right';
};
export function Text(props: Props): ReactElement {
	const {
		children,
		bold,
		muted,
		small,
		large,
		overflowEllipsis,
		runningText,
		disabled,
		color = 'black',
		textAlign = 'left'
	} = props;

	let style: any = {
		textAlign,
		color
	};
	if (bold) {
		style = {
			...style,
			fontWeight: 'bold'
		};
	}

	const classNames = ['bp4-ui-text'];
	if (muted) {
		classNames.push('bp4-text-muted');
	}

	if (small) {
		classNames.push('bp4-text-small');
	} else if (large) {
		classNames.push('bp4-text-large');
	}

	if (overflowEllipsis) {
		classNames.push('bp4-text-overflow-ellipsis');
	}

	if (runningText) {
		classNames.push('bp4-running-text');
	}

	if (disabled) {
		classNames.push('bp4-text-disabled');
	}

	return (
		<span className={classNames.join(',')} style={style}>
			{children}
		</span>
	);
}
export function SmallText({ children, bold }: Props): ReactElement {
	const style = bold ? { fontWeight: 'bold' } : {};
	return (
		<span className="bp4-text-small" style={style}>
			{children}
		</span>
	);
}
export function LargeText({ children, bold }: Props): ReactElement {
	const style = bold ? { fontWeight: 'bold' } : {};
	return (
		<span className="bp4-text-large" style={style}>
			{children}
		</span>
	);
}
