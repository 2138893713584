import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useNullableTokens } from '@techshift/react-core';

export function AppIndexRedirect(): ReactElement {
	const { tokens } = useNullableTokens();

	if (tokens) {
		return <Navigate to="/home" replace />;
	} else {
		return <Navigate to="/login" replace />;
	}
}
