import { ReactElement } from 'react';
import styles from './arc-operator-sidebar.module.scss';
import { H2, H3 } from '@blueprintjs/core';
import { ArcOperator } from '@techshift/callie-private-api-typescript';
import { SidebarDataItem, Text } from '../../../ui/components';
import {
	DeleteArcOperatorButton,
	RestoreArcOperatorButton,
	UpdateArcOperatorButton
} from '..';

type ArcOperatorSidebarProps = {
	operator: ArcOperator;
	onUpdateArcOperator: (operator: ArcOperator) => void;
	onDeleteArcOperator: () => void;
};
export function ArcOperatorSidebar(
	props: ArcOperatorSidebarProps
): ReactElement {
	const { operator, onUpdateArcOperator, onDeleteArcOperator } = props;

	return (
		<div className={styles.container}>
			<H2>{operator.firstName + ' ' + operator.lastName}</H2>
			<Text>{operator.email}</Text>
			<br />
			<br />
			<H3>Details</H3>
			<SidebarDataItem title="Arc operator ID" text={operator.id} />
			<br />
			<br />
			<SidebarDataItem title="First name" text={operator.firstName} />
			<br />
			<br />
			<SidebarDataItem title="Last name" text={operator.lastName} />
			<br />
			<br />
			<SidebarDataItem title="Email" text={operator.email} />
			<br />
			<br />
			<SidebarDataItem
				title="Created at"
				text={new Date(operator.createdAt).toLocaleString()}
			/>
			<br />
			<br />
			<SidebarDataItem
				title="Updated at"
				text={new Date(operator.updatedAt).toLocaleString()}
			/>
			<br />
			<br />
			{operator.deletedAt && (
				<>
					<SidebarDataItem
						title="Deleted at"
						text={new Date(operator.deletedAt).toLocaleString()}
					/>
					<br />
					<br />
				</>
			)}

			<UpdateArcOperatorButton
				operator={operator}
				onSuccess={onUpdateArcOperator}
			/>
			<br />
			<br />
			{operator.deletedAt ? (
				<>
					<RestoreArcOperatorButton
						operator={operator}
						onSuccess={onUpdateArcOperator}
					/>
					<br />
					<br />
					<DeleteArcOperatorButton
						hard
						operator={operator}
						onSuccess={onDeleteArcOperator}
					/>
				</>
			) : (
				<DeleteArcOperatorButton
					operator={operator}
					onSuccess={onDeleteArcOperator}
				/>
			)}
		</div>
	);
}
