import { ReactElement, useEffect, useState, useRef, useCallback } from 'react';
import styles from './user-guardians.module.scss';
import { GuardiansResponse } from '@techshift/callie-private-api-typescript';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { Button, H4, Intent, NonIdealState, Spinner } from '@blueprintjs/core';
import { UserGuardiansTable } from './user-guardians-table';
import { CreateGuardianDialog } from '../create-guardian/create-guardian-dialog';

type UserGuardiansProps = {
	userId: string;
};

export function UserGuardians(props: UserGuardiansProps): ReactElement {
	const { userId } = props;

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [showCreate, setShowCreate] = useState(false);
	const [userGuardians, setUserGuardians] = useState<GuardiansResponse | null>(
		null
	);

	const fetch = useCallback(() => {
		setUserGuardians(null);
		setLoading(true);
		setError('');
		openApiRef.current.users
			.getGuardians(userId)
			.then((response) => {
				setUserGuardians(response.data);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: () => {
						setError('Unknown error');
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, [userId]);

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return (
		<div className={styles.container}>
			<div className={styles.headerContainer}>
				<H4>Guardians</H4>
				<Button
					icon={'add'}
					intent={Intent.PRIMARY}
					disabled={
						userGuardians ? userGuardians.data.guardians.data.length >= 3 : true
					}
					onClick={() => {
						setShowCreate(true);
					}}
				>
					{`Create`}
				</Button>
			</div>
			<>
				{loading && <NonIdealState icon={<Spinner />} title={'Loading'} />}
				{error && (
					<NonIdealState
						icon={'error'}
						title={'Error'}
						description={error}
						action={
							<Button outlined onClick={fetch}>
								Retry
							</Button>
						}
					/>
				)}
				{userGuardians && (
					<UserGuardiansTable
						userId={userId}
						guardians={userGuardians.data.guardians}
						onDelete={(guardianId) => {
							setUserGuardians((current) => {
								if (!current) {
									return null;
								}

								return {
									...current,
									data: {
										...current.data,
										guardians: {
											...current.data.guardians,
											data: current.data.guardians.data.filter(
												(guardian) => guardian.phone !== guardianId
											)
										}
									}
								};
							});
						}}
						onUpdate={(guardian) => {
							setUserGuardians((current) => {
								if (!current) {
									return null;
								}

								return {
									...current,
									data: {
										...current.data,
										guardians: {
											...current.data.guardians,
											data: current.data.guardians.data.map((g) => {
												if (g.phone === guardian.phone) {
													return guardian;
												} else {
													return g;
												}
											})
										}
									}
								};
							});
						}}
					/>
				)}
				<CreateGuardianDialog
					userId={userId}
					isOpen={showCreate}
					onClose={() => {
						setShowCreate(false);
					}}
					onSuccess={(guardian) => {
						setShowCreate(false);
						setUserGuardians((current) => {
							if (!current) {
								return null;
							} else {
								return {
									...current,
									data: {
										...current.data,
										guardians: {
											...current.data.guardians,
											data: [...current.data.guardians.data, guardian]
										}
									}
								};
							}
						});
					}}
				/>
			</>
		</div>
	);
}
