import { ReactElement, useId } from 'react';
import { Button, FormGroup, H3, InputGroup, Intent } from '@blueprintjs/core';
import { useForm, Controller } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

export type UpdateGuardianFormData = {
	name: string;
};

const schema = Joi.object({
	name: Joi.string().max(64).required()
});

export type UpdateGuardianFormProps = {
	initialName: string;
	onSubmit?: (data: UpdateGuardianFormData) => void;
	loading?: boolean;
};
export function UpdateGuardianForm(
	props: UpdateGuardianFormProps
): ReactElement {
	const {
		initialName,
		onSubmit = () => {
			return;
		},
		loading
	} = props;

	const id = useId();

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<UpdateGuardianFormData>({
		resolver: joiResolver(schema)
	});

	return (
		<>
			<H3>Edit guardian</H3>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormGroup
					label="Name"
					labelFor={'name' + id}
					labelInfo="(required)"
					subLabel={errors.name?.message}
					intent={!errors.name?.message ? Intent.NONE : Intent.DANGER}
				>
					<Controller
						name="name"
						control={control}
						defaultValue={initialName}
						render={({ field }) => (
							<InputGroup
								id={'name' + id}
								maxLength={64}
								intent={!errors.name?.message ? Intent.NONE : Intent.DANGER}
								{...field}
							/>
						)}
					/>
				</FormGroup>

				<Button intent={Intent.PRIMARY} type={'submit'} loading={loading}>
					Confirm
				</Button>
			</form>
		</>
	);
}
