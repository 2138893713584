import { ReactElement, useEffect, useState } from 'react';
import { UpdateAdminForm, UpdateAdminFormData } from '../update-admin-form';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { Admin } from '@techshift/callie-private-api-typescript';

export type UpdateAdminProps = {
	admin: Admin;
	onSuccess?: (admin: Admin) => void;
};
export function UpdateAdmin(props: UpdateAdminProps): ReactElement {
	const { admin, onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = ({ firstName, lastName, email }: UpdateAdminFormData) => {
		setLoading(true);
		setError('');
		openApi.admins
			.updateAdmin(admin.id, { firstName, lastName, email })
			.then((response) => {
				AppToaster.show({ message: 'Admin updated', intent: 'success' });
				onSuccess?.(response.data.data.admin);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'ADMIN_ALREADY_EXISTS') {
							setError('Admin with this email already exists');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return (
		<UpdateAdminForm
			initialFirstName={admin.firstName}
			initialLastName={admin.lastName}
			initialEmail={admin.email}
			loading={loading}
			onSubmit={onSubmit}
		/>
	);
}
