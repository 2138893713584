import { ReactElement, useEffect, useState } from 'react';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { Admin } from '@techshift/callie-private-api-typescript';
import { DeleteAdminForm } from '../delete-admin-form';

export type DeleteAdminProps = {
	admin: Admin;
	hard?: boolean;
	onSuccess?: () => void;
};
export function DeleteAdmin(props: DeleteAdminProps): ReactElement {
	const { admin, hard, onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = () => {
		setLoading(true);
		setError('');
		openApi.admins
			.deleteAdmin(admin.id, { hard })
			.then(() => {
				AppToaster.show({ message: 'Admin deleted', intent: 'success' });
				onSuccess?.();
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'ADMIN_NOT_FOUND') {
							setError('Cannot delete admin as they do not exist');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return (
		<DeleteAdminForm
			id={admin.id}
			hard={hard}
			loading={loading}
			onSubmit={onSubmit}
		/>
	);
}
