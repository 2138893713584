import { ReactElement } from 'react';
import { useBattery, useLocations, useTasks } from '../../providers';
import { TaskMapView } from './task-map-view';

export function TaskMap(): ReactElement {
	const { user } = useTasks();
	const { locations, loading } = useLocations();
	const { battery } = useBattery();

	const { task } = useTasks();

	return (
		<TaskMapView
			taskStatus={task.status}
			locations={locations}
			userImage={user.image}
			loading={loading}
			battery={battery}
		/>
	);
}
