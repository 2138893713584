import { H2 } from '@blueprintjs/core';
import { ReactElement } from 'react';
import styles from './app-loading.module.scss';

export function AppLoading(): ReactElement {
	return (
		<div className={styles.container}>
			<H2>{"We're loading your data..."}</H2>
		</div>
	);
}
