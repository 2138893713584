import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {
	ApiProvider,
	useUnauthenticatedApi,
	useNullableTokens
} from '@techshift/react-core';
import { OpenApiProvider, useOpenApi } from '../../providers';

export function AppUnauthInit(): ReactElement {
	const { tokens } = useNullableTokens();

	if (tokens) {
		return <Navigate to="/" replace />;
	}

	return (
		<OpenApiProvider>
			<AppUnauthInit2 />
		</OpenApiProvider>
	);
}

export function AppUnauthInit2(): ReactElement {
	const { tokens } = useNullableTokens();

	const { openApi } = useOpenApi();
	const { api } = useUnauthenticatedApi({ axiosInstance: openApi.instance });

	if (tokens) {
		return <Navigate to="/" replace />;
	}

	return (
		<ApiProvider api={api}>
			<Outlet />
		</ApiProvider>
	);
}
