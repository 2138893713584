import { ReactElement } from 'react';
import { Button, H3 } from '@blueprintjs/core';
import { ArcOperator } from '@techshift/callie-private-api-typescript';
import { Text } from '../../../ui/components';

export type CreatedArcOperatorProps = {
	operator: ArcOperator;
	password: string;
	onCreateAnother: () => void;
};
export function CreatedArcOperator(
	props: CreatedArcOperatorProps
): ReactElement {
	const { operator, password, onCreateAnother } = props;
	return (
		<>
			<H3>Created ARC operator</H3>
			<Text>
				A password has been genreated for this ARC operator. Please copy and
				store this password now as it will not be shown again.
			</Text>

			<br />
			<br />

			<Text bold>First Name</Text>
			<br />
			<Text>{operator.firstName}</Text>

			<br />
			<br />

			<Text bold>Last Name</Text>
			<br />
			<Text>{operator.lastName}</Text>

			<br />
			<br />

			<Text bold>Email</Text>
			<br />
			<Text>{operator.email}</Text>

			<br />
			<br />

			<Text bold>Password</Text>
			<br />
			<Text>{password}</Text>

			<br />
			<br />
			<Button onClick={onCreateAnother}>Create another</Button>
		</>
	);
}
