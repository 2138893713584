import { ReactElement, useState } from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import { DeleteAdmin } from '..';
import { Admin } from '@techshift/callie-private-api-typescript';

export type DeleteAdminButtonProps = {
	admin: Admin;
	hard?: boolean;
	onSuccess?: () => void;
};
export function DeleteAdminButton(props: DeleteAdminButtonProps): ReactElement {
	const { admin, hard, onSuccess } = props;

	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Button
				icon={'trash'}
				onClick={() => {
					setIsOpen(true);
				}}
				intent={Intent.DANGER}
			>
				{`${hard ? 'Hard' : 'Soft'} delete`}
			</Button>
			<Dialog isOpen={isOpen}>
				<div className={Classes.DIALOG_BODY}>
					<DeleteAdmin
						admin={admin}
						hard={hard}
						onSuccess={() => {
							onSuccess?.();
							setIsOpen(false);
						}}
					/>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							onClick={() => {
								setIsOpen(false);
							}}
						>
							Close
						</Button>
					</div>
				</div>
			</Dialog>
		</>
	);
}
