import { ReactElement, useId } from 'react';
import { Button, FormGroup, H3, InputGroup, Intent } from '@blueprintjs/core';
import { useForm, Controller } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

type UpdateUserFormData = {
	firstName: string;
	lastName: string;
	pin?: string;
};

export type UpdateUserFormSubmitData = {
	firstName: string;
	lastName: string;
	pin: string | null;
};

const schema = Joi.object({
	firstName: Joi.string().max(64).required(),
	lastName: Joi.string().max(64).required(),
	pin: Joi.string().length(4).allow('').optional()
});

export type UpdateUserFormProps = {
	initialFirstName: string;
	initialLastName: string;
	initialPin: string | null;
	onSubmit?: (data: UpdateUserFormSubmitData) => void;
	loading?: boolean;
};
export function UpdateUserForm(props: UpdateUserFormProps): ReactElement {
	const {
		initialFirstName,
		initialLastName,
		initialPin,
		onSubmit = () => {
			return;
		},
		loading
	} = props;

	const id = useId();

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<UpdateUserFormData>({
		resolver: joiResolver(schema)
	});

	return (
		<>
			<H3>Edit user</H3>
			<form
				onSubmit={handleSubmit(({ firstName, lastName, pin }) => {
					onSubmit({
						firstName,
						lastName,
						pin: pin || null
					});
				})}
			>
				<FormGroup
					label="First name"
					labelFor={'firstName' + id}
					labelInfo="(required)"
					subLabel={errors.firstName?.message}
					intent={!errors.firstName?.message ? Intent.NONE : Intent.DANGER}
				>
					<Controller
						name="firstName"
						control={control}
						defaultValue={initialFirstName}
						render={({ field }) => (
							<InputGroup
								id={'firstName' + id}
								maxLength={64}
								intent={
									!errors.firstName?.message ? Intent.NONE : Intent.DANGER
								}
								{...field}
							/>
						)}
					/>
				</FormGroup>
				<FormGroup
					label="Last name"
					labelFor={'lastName' + id}
					labelInfo="(required)"
					subLabel={errors.lastName?.message}
					intent={!errors.lastName?.message ? Intent.NONE : Intent.DANGER}
				>
					<Controller
						name="lastName"
						control={control}
						defaultValue={initialLastName}
						render={({ field }) => (
							<InputGroup
								id={'lastName' + id}
								maxLength={64}
								intent={!errors.lastName?.message ? Intent.NONE : Intent.DANGER}
								{...field}
							/>
						)}
					/>
				</FormGroup>
				<FormGroup
					label="PIN"
					labelFor={'pin' + id}
					labelInfo="(optional)"
					subLabel={errors.pin?.message}
					intent={!errors.pin?.message ? Intent.NONE : Intent.DANGER}
				>
					<Controller
						name="pin"
						control={control}
						defaultValue={initialPin || ''}
						render={({ field }) => (
							<InputGroup
								id={'pin' + id}
								maxLength={4}
								intent={!errors.pin?.message ? Intent.NONE : Intent.DANGER}
								{...field}
							/>
						)}
					/>
				</FormGroup>

				<Button intent={Intent.PRIMARY} type={'submit'} loading={loading}>
					Confirm
				</Button>
			</form>
		</>
	);
}
