import { ReactElement, useState } from 'react';
import { Button, Classes, Dialog } from '@blueprintjs/core';
import { UpdateUser } from '..';
import { User } from '@techshift/callie-private-api-typescript';

export type UpdateUserButtonProps = {
	user: User;
	onSuccess?: (user: User) => void;
};
export function UpdateUserButton(props: UpdateUserButtonProps): ReactElement {
	const { user, onSuccess } = props;

	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Button
				icon={'edit'}
				onClick={() => {
					setIsOpen(true);
				}}
			>
				Edit
			</Button>
			<Dialog isOpen={isOpen}>
				<div className={Classes.DIALOG_BODY}>
					<UpdateUser
						user={user}
						onSuccess={(user) => {
							onSuccess?.(user);
							setIsOpen(false);
						}}
					/>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							onClick={() => {
								setIsOpen(false);
							}}
						>
							Close
						</Button>
					</div>
				</div>
			</Dialog>
		</>
	);
}
