import { ReactElement } from 'react';
import { Button, Classes, Dialog } from '@blueprintjs/core';
import { Guardian } from '@techshift/callie-private-api-typescript';
import { UpdateGuardian } from './update-guardian';

export type UpdateGuardianDialogProps = {
	userId: string;
	guardian: Guardian;
	isOpen?: boolean;
	onSuccess?: (guardian: Guardian) => void;
	onClose?: () => void;
};
export function UpdateGuardianDialog(
	props: UpdateGuardianDialogProps
): ReactElement {
	const { userId, guardian, isOpen, onSuccess, onClose } = props;

	return (
		<Dialog isOpen={isOpen}>
			<div className={Classes.DIALOG_BODY}>
				<UpdateGuardian
					userId={userId}
					guardian={guardian}
					onSuccess={onSuccess}
				/>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={onClose}>Close</Button>
				</div>
			</div>
		</Dialog>
	);
}
