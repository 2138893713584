import { ReactElement } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { EnvProvider, NullableTokensProvider } from '@techshift/react-core';
import {
	AdminIdPage,
	AdminsOverviewPage,
	AdminsPermissionsPage,
	AdminsRolesPage,
	HomePage,
	MainPage,
	NotFoundPage,
	SubMainPage,
	UserIdPage,
	UsersOverviewPage
} from './pages';
import {
	AppAuthInit,
	AppIndexRedirect,
	AppUnauthInit
} from './core/components';
import { LoginPage } from './pages/login';
import { AdminsDeletedPage } from './pages/admins/admins-deleted';
import { UsersDeletedPage } from './pages/users/users-deleted';
import { TasksOpenPage } from './pages/tasks';
import { TasksClosedPage } from './pages/tasks/tasks-closed';
import { TaskIdPage } from './pages/tasks/task-id';
import { TasksDeletedPage } from './pages/tasks/tasks-deleted';
import { ArcOperatorIdPage, ArcOperatorsOverviewPage } from './pages/arc';
import { ArcOperatorsDeletedPage } from './pages/arc/arc-operators-deleted';

function App(): ReactElement {
	const defaultBaseUrl = process.env.REACT_APP_BASE_URL || '';

	return (
		<EnvProvider defaultBaseUrl={defaultBaseUrl}>
			<NullableTokensProvider>
				<Routes>
					<Route path="/" element={<AppIndexRedirect />} />

					<Route element={<AppUnauthInit />}>
						<Route path="login" element={<LoginPage />} />
					</Route>

					<Route path="/not-found" element={<NotFoundPage />} />
					<Route path="*" element={<Navigate to="/not-found" />} />

					<Route
						element={
							<AppAuthInit>
								<MainPage />
							</AppAuthInit>
						}
					>
						<Route index element={<HomePage />} />
						<Route path="home" element={<HomePage />} />
						<Route
							path="admins"
							element={
								<SubMainPage
									title="Admins"
									items={[
										{ text: 'Overview', link: '/admins' },
										{ text: 'Deleted', link: '/admins/deleted' }
									]}
								/>
							}
						>
							<Route path="" element={<AdminsOverviewPage />} />
							<Route path=":adminId" element={<AdminIdPage />} />
							<Route path="deleted" element={<AdminsDeletedPage />} />
							<Route path="roles" element={<AdminsRolesPage />} />
							<Route path="permissions" element={<AdminsPermissionsPage />} />
						</Route>

						<Route
							path="arc"
							element={
								<SubMainPage
									title="ARC"
									items={[
										{ text: 'Overview', link: '/arc' },
										{ text: 'Deleted', link: '/arc/deleted' }
									]}
								/>
							}
						>
							<Route path="" element={<ArcOperatorsOverviewPage />} />
							<Route path=":operatorId" element={<ArcOperatorIdPage />} />
							<Route path="deleted" element={<ArcOperatorsDeletedPage />} />
						</Route>

						<Route
							path="tasks"
							element={
								<SubMainPage
									title="Tasks"
									items={[
										{ text: 'Open', link: '/tasks' },
										{ text: 'Closed', link: '/tasks/closed' },
										{ text: 'Deleted', link: '/tasks/deleted' }
									]}
								/>
							}
						>
							<Route path="" element={<TasksOpenPage />} />
							<Route path=":taskId" element={<TaskIdPage />} />
							<Route path="closed" element={<TasksClosedPage />} />
							<Route path="deleted" element={<TasksDeletedPage />} />
						</Route>

						<Route
							path="users"
							element={
								<SubMainPage
									title="Users"
									items={[
										{ text: 'Overview', link: '/users' },
										{ text: 'Deleted', link: '/users/deleted' }
									]}
								/>
							}
						>
							<Route path="" element={<UsersOverviewPage />} />
							<Route path=":userId" element={<UserIdPage />} />
							<Route path="deleted" element={<UsersDeletedPage />} />
						</Route>
					</Route>
				</Routes>
			</NullableTokensProvider>
		</EnvProvider>
	);
}

export default App;
