import { ReactElement, useId } from 'react';
import { Button, FormGroup, H3, InputGroup, Intent } from '@blueprintjs/core';
import { useForm, Controller } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { validatePhone } from '@techshift/react-core';

export type CreateGuardianFormData = {
	name: string;
	phone: string;
};

const schema = Joi.object({
	name: Joi.string().max(64).required(),
	phone: Joi.string()
		.custom((value, helper) => {
			if (validatePhone(value).length > 0) {
				// @ts-ignore
				return helper.message('phone is incorrect');
			}
			return value;
		})
		.required()
});

export type CreateGuardianFormProps = {
	onSubmit?: (data: CreateGuardianFormData) => void;
	loading?: boolean;
};
export function CreateGuardianForm(
	props: CreateGuardianFormProps
): ReactElement {
	const {
		onSubmit = () => {
			return;
		},
		loading
	} = props;

	const id = useId();

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<CreateGuardianFormData>({
		resolver: joiResolver(schema)
	});

	return (
		<>
			<H3>Create guardian</H3>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormGroup
					label="Name"
					labelFor={'name' + id}
					labelInfo="(required)"
					subLabel={errors.name?.message}
					intent={!errors.name?.message ? Intent.NONE : Intent.DANGER}
				>
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<InputGroup
								id={'name' + id}
								maxLength={64}
								intent={!errors.name?.message ? Intent.NONE : Intent.DANGER}
								{...field}
							/>
						)}
					/>
				</FormGroup>
				<FormGroup
					label="Phone"
					labelFor={'phone' + id}
					labelInfo="(required)"
					subLabel={errors.phone?.message}
					intent={!errors.phone?.message ? Intent.NONE : Intent.DANGER}
				>
					<Controller
						name="phone"
						control={control}
						render={({ field }) => (
							<InputGroup
								id={'phone' + id}
								maxLength={64}
								intent={!errors.phone?.message ? Intent.NONE : Intent.DANGER}
								{...field}
							/>
						)}
					/>
				</FormGroup>

				<Button intent={Intent.PRIMARY} type={'submit'} loading={loading}>
					Create
				</Button>
			</form>
		</>
	);
}
