import { ReactElement, useEffect, useState } from 'react';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { Guardian } from '@techshift/callie-private-api-typescript';
import {
	CreateGuardianForm,
	CreateGuardianFormData
} from './create-guardian-form';

export type CreateGuardianProps = {
	userId: string;
	onSuccess?: (guardian: Guardian) => void;
};
export function CreateGuardian(props: CreateGuardianProps): ReactElement {
	const { userId, onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = ({ name, phone }: CreateGuardianFormData) => {
		setLoading(true);
		setError('');
		openApi.users
			.createGuardian(userId, { name, phone })
			.then((response) => {
				AppToaster.show({ message: 'Guardian created', intent: 'success' });
				onSuccess?.(response.data.data.guardian);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'GUARDIAN_MAX_LIMIT') {
							setError('Max guardian limit reached');
						} else if (response.data.message === 'GUARDIAN_NOT_UNIQUE') {
							setError('A guardian with this phone number already exists');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return <CreateGuardianForm loading={loading} onSubmit={onSubmit} />;
}
