import { ReactElement, useState } from 'react';
import { Button, Classes, Dialog } from '@blueprintjs/core';
import { CreateArcOperator } from '..';

export type CreateArcOperatorButtonProps = {};
export function CreateArcOperatorButton(
	props: CreateArcOperatorButtonProps
): ReactElement {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Button
				icon="add"
				onClick={() => {
					setIsOpen(true);
				}}
			>
				Create ARC operator
			</Button>
			<Dialog isOpen={isOpen}>
				<div className={Classes.DIALOG_BODY}>
					<CreateArcOperator />
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							onClick={() => {
								setIsOpen(false);
							}}
						>
							Close
						</Button>
					</div>
				</div>
			</Dialog>
		</>
	);
}
