import { ReactElement, useState } from 'react';
import { Guardian, Guardians } from '@techshift/callie-private-api-typescript';
import { Button, HTMLTable, Intent, Menu } from '@blueprintjs/core';
import { MenuItem2, Popover2 } from '@blueprintjs/popover2';
import { DeleteGuardianDialog } from '../delete-guardian';
import { UpdateGuardianDialog } from '../update-guardian';
import { Text } from '../../../ui/components';

type UserGuardiansTableProps = {
	userId: string;
	guardians: Guardians;
	onDelete?: (guardianPhone: string) => void;
	onUpdate?: (guardian: Guardian) => void;
};

export function UserGuardiansTable(
	props: UserGuardiansTableProps
): ReactElement {
	const { userId, guardians, onDelete, onUpdate } = props;

	const [toDelete, setToDelete] = useState('');
	const [toEdit, setToEdit] = useState<Guardian | null>();

	return (
		<>
			<HTMLTable striped interactive>
				<thead>
					<tr>
						<th>Name</th>
						<th>Phone</th>
						<th>Updated</th>
						<th>Created</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{guardians.data.map((guardian, index) => (
						<tr key={index}>
							<td>{guardian.name}</td>
							<td>{guardian.phone}</td>
							<td>{new Date(guardian.updatedAt).toLocaleString()}</td>
							<td>{new Date(guardian.createdAt).toLocaleString()}</td>
							<td>
								<Popover2
									enforceFocus={false}
									placement="bottom-end"
									interactionKind={'click'}
									minimal
									content={
										<Menu>
											<MenuItem2
												icon="edit"
												text="Edit"
												onClick={() => {
													setToEdit(guardian);
												}}
											/>
											<MenuItem2
												icon="trash"
												text="Delete"
												intent={Intent.DANGER}
												onClick={() => {
													setToDelete(guardian.phone);
												}}
											/>
										</Menu>
									}
									renderTarget={({ isOpen, ref, ...p }) => (
										<Button
											{...p}
											active={isOpen}
											elementRef={ref as any}
											minimal
											small
											icon="more"
										/>
									)}
								/>
							</td>
						</tr>
					))}
					{guardians.data.length === 0 && (
						<td colSpan={5} style={{ textAlign: 'center' }}>
							<Text>No Guardians</Text>
						</td>
					)}
				</tbody>
			</HTMLTable>
			<DeleteGuardianDialog
				userId={userId}
				guardianPhone={toDelete}
				isOpen={!!toDelete}
				onClose={() => setToDelete('')}
				onSuccess={() => {
					onDelete?.(toDelete);
					setToDelete('');
				}}
			/>
			{toEdit && (
				<UpdateGuardianDialog
					userId={userId}
					guardian={toEdit}
					isOpen={!!toEdit}
					onClose={() => setToEdit(null)}
					onSuccess={(guardian) => {
						onUpdate?.(guardian);
						setToEdit(null);
					}}
				/>
			)}
		</>
	);
}
