import { ReactElement } from 'react';
import { Button, H3, Intent } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import { Text } from '../../../ui/components';

type DeleteGuardianFormData = {};

export type DeleteGuardianFormProps = {
	onSubmit?: () => void;
	loading?: boolean;
};
export function DeleteGuardianForm(
	props: DeleteGuardianFormProps
): ReactElement {
	const {
		onSubmit = () => {
			return;
		},
		loading
	} = props;

	const { handleSubmit } = useForm<DeleteGuardianFormData>({});

	return (
		<>
			<H3>Delete guardian</H3>
			<Text>Are you sure you want to delete the guardian?</Text>
			<br />
			<br />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Button intent={Intent.DANGER} type={'submit'} loading={loading}>
					Delete
				</Button>
			</form>
		</>
	);
}
