import { ReactElement, useEffect, useState } from 'react';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { Admin } from '@techshift/callie-private-api-typescript';
import { RestoreAdminForm } from '../restore-admin-form';

export type RestoreAdminProps = {
	admin: Admin;
	onSuccess?: (admin: Admin) => void;
};
export function RestoreAdmin(props: RestoreAdminProps): ReactElement {
	const { admin, onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = () => {
		setLoading(true);
		setError('');
		openApi.admins
			.updateAdmin(admin.id, { deletedAt: null })
			.then((response) => {
				AppToaster.show({ message: 'Admin restored', intent: 'success' });
				onSuccess?.(response.data.data.admin);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'ADMIN_NOT_FOUND') {
							setError('Cannot restore admin as they do not exist');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return (
		<RestoreAdminForm id={admin.id} loading={loading} onSubmit={onSubmit} />
	);
}
