import { ReactElement, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, NonIdealState, Spinner } from '@blueprintjs/core';
import { useOpenApi } from '../../../core/providers';
import { Admin } from '@techshift/callie-private-api-typescript';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { AdminSidebar } from '../../../admins/components';

export function AdminIdPage(): ReactElement {
	const { adminId } = useParams();
	const navigate = useNavigate();

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [admin, setAdmin] = useState<Admin | null>(null);

	const fetch = useCallback((id: string) => {
		setAdmin(null);
		setLoading(true);
		setError('');
		openApi.admins
			.getAdmin(id)
			.then((response) => {
				setAdmin(response.data.data.admin);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'ADMIN_NOT_FOUND') {
							setError('Admin not found');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	useEffect(() => {
		if (!adminId) {
			return;
		}

		fetch(adminId);
	}, [adminId, fetch]);

	return (
		<div style={{ flex: 1, paddingTop: 25, paddingLeft: 25 }}>
			{loading && <NonIdealState icon={<Spinner />} title={'Loading'} />}
			{error && (
				<NonIdealState
					icon={'error'}
					title={'Error'}
					description={error}
					action={
						<Button
							outlined
							onClick={() => {
								if (adminId) {
									fetch(adminId);
								}
							}}
						>
							Retry
						</Button>
					}
				/>
			)}

			{admin && (
				<AdminSidebar
					admin={admin}
					onUpdateAdmin={setAdmin}
					onDeleteAdmin={() => {
						navigate(`/admins`);
					}}
				/>
			)}
		</div>
	);
}
