import { ReactElement } from 'react';
import { TaskLog, User } from '@techshift/callie-private-api-typescript';
import styles from './task-logs-table.module.scss';
import dayjs from 'dayjs';
import { HTMLTable } from '@blueprintjs/core';

export type TaskLogsTableQuery = {
	limit?: number;
	offset?: number;
	sortOrder?: 'asc' | 'desc';
};

type TaskLogsTableProps = {
	taskLogs: TaskLog[];
	user: User;
};

export function TaskLogsTable(props: TaskLogsTableProps): ReactElement {
	const { taskLogs, user } = props;

	function getLogTitle(item: TaskLog): string {
		switch (item.taskLogType) {
			case 'opened_timed':
				let str = 'Watch over me started';
				if (item.note) {
					str += ` with note: "${item.note}"`;
				}
				return str;
			case 'timeout_updated':
				const timeoutAt = item.timeoutAt;
				if (timeoutAt) {
					const timeoutAtDate = dayjs(timeoutAt);
					return `Updated time to ${timeoutAtDate.format('DD MMM YY h:mma')}`;
				}
				return 'Updated time';
			case 'closed':
				return 'Marked as safe';
			case 'note':
				return `Added note: "${item.note || ''}"`;
			case 'watching':
				const guardianPhone = item.guardianPhone;
				const guardian = user.guardians.find((g) => g.phone === guardianPhone);
				let name: string;
				if (guardian) {
					name = guardian.name;
				} else {
					name = 'A deleted guardian opened the link';
				}
				return `"${name}" (Guardian) opened the link`;
			case 'opened_instant_alert':
				return 'Alert triggered: instant';
			case 'alerted_timeout':
				return 'Alert triggered: timeout reached';
			case 'alerted_manual_app':
				return 'Alert triggered: Pressed in-app';
			case 'alerted_manual_button':
				return 'Alert triggered: Pressed Jewellery';
			default:
				return 'Unknown log';
		}
	}

	return (
		<div className={styles.container}>
			<>
				<HTMLTable striped interactive>
					<thead>
						<tr>
							<th>Type</th>
							<th>CREATED</th>
						</tr>
					</thead>
					<tbody>
						{taskLogs.map((taskLog, index) => (
							<tr key={index}>
								<td>{getLogTitle(taskLog)}</td>
								<td>{new Date(taskLog.createdAt).toLocaleString()}</td>
							</tr>
						))}
					</tbody>
				</HTMLTable>
			</>
		</div>
	);
}
