import { ReactElement, useEffect, useState } from 'react';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { User } from '@techshift/callie-private-api-typescript';
import { UpdateUserForm, UpdateUserFormSubmitData } from './update-user-form';

export type UpdateUserProps = {
	user: User;
	onSuccess?: (user: User) => void;
};
export function UpdateUser(props: UpdateUserProps): ReactElement {
	const { user, onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = ({ firstName, lastName, pin }: UpdateUserFormSubmitData) => {
		setLoading(true);
		setError('');
		openApi.users
			.updateUser(user.id, { firstName, lastName, pin })
			.then((response) => {
				AppToaster.show({ message: 'User updated', intent: 'success' });
				onSuccess?.(response.data.data.user);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						setError('Unknown error');
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return (
		<UpdateUserForm
			initialFirstName={user.firstName}
			initialLastName={user.lastName}
			initialPin={user.pin}
			loading={loading}
			onSubmit={onSubmit}
		/>
	);
}
