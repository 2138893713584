import { ReactElement, useEffect, useState, useRef } from 'react';
import {
	Task,
	TasksResponseData
} from '@techshift/callie-private-api-typescript';
import { useOpenApi } from '../../../core/providers';
import { TasksTableView } from './tasks-table-view';

export type TasksTableQuery = {
	userId?: string;
	limit?: number;
	offset?: number;
	sortBy?: 'timeoutAt' | 'alertedAt' | 'closedAt' | 'createdAt';
	sortOrder?: 'asc' | 'desc';
	status?: 'open' | 'closed';
};

type TasksTableProps = {
	initialQuery: TasksTableQuery;
	onQueryChange?: (query: TasksTableQuery) => void;
	onTaskClick?: (task: Task) => void;
	showDeleted?: boolean;
};

export function TasksTable(props: TasksTableProps): ReactElement {
	const { initialQuery, onQueryChange, onTaskClick, showDeleted } = props;

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);

	const [tasksResponse, setTasksResponse] = useState<TasksResponseData | null>(
		null
	);

	const userId = initialQuery.userId;
	const status = initialQuery.status;
	const limit = initialQuery.limit;
	const [offset, setOffset] = useState<number | undefined>(initialQuery.offset);
	const [sortBy, setSortBy] = useState<
		'timeoutAt' | 'alertedAt' | 'closedAt' | 'createdAt' | undefined
	>(initialQuery.sortBy);
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>(
		initialQuery.sortOrder
	);

	useEffect(() => {
		setTasksResponse(null);
		openApiRef.current.tasks
			.getTasks({
				userId,
				limit,
				offset,
				sortBy,
				sortOrder,
				status,
				deleted: showDeleted
			})
			.then((response) => {
				setTasksResponse(response.data.data);
			});
	}, [offset, sortBy, sortOrder, showDeleted, status, userId, limit]);

	useEffect(() => {
		onQueryChange?.({
			offset,
			sortBy,
			sortOrder
		});
	}, [onQueryChange, offset, sortBy, sortOrder]);

	return (
		<TasksTableView
			data={tasksResponse}
			onTaskClick={onTaskClick}
			onCreatedAtSortClick={() => {
				setSortBy('createdAt');
				setSortOrder((sortOrder) => (sortOrder === 'asc' ? 'desc' : 'asc'));
				setOffset(undefined);
			}}
			showDeleted={showDeleted}
			sortBy={sortBy}
			sortOrder={sortOrder}
			onPreviousPress={() => {
				if (tasksResponse) {
					setOffset(tasksResponse.tasks.offset - tasksResponse.tasks.limit);
				}
			}}
			onNextPress={() => {
				if (tasksResponse) {
					setOffset(tasksResponse.tasks.offset + tasksResponse.tasks.limit);
				}
			}}
		/>
	);
}
