import { ReactElement, useEffect, useState } from 'react';
import { useOpenApi } from '../../../core/providers';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { DeleteGuardianForm } from '.';

export type DeleteGuardianProps = {
	userId: string;
	guardianPhone: string;
	onSuccess?: () => void;
};
export function DeleteGuardian(props: DeleteGuardianProps): ReactElement {
	const { userId, guardianPhone, onSuccess } = props;

	const { openApi } = useOpenApi();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	const onSubmit = () => {
		setLoading(true);
		setError('');
		openApi.users
			.deleteGuardian(userId, guardianPhone)
			.then(() => {
				AppToaster.show({ message: 'Guardian deleted', intent: 'success' });
				onSuccess?.();
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: () => {
						setError('Unknown error');
					}
				});
			})
			.finally(() => setLoading(false));
	};
	return <DeleteGuardianForm loading={loading} onSubmit={onSubmit} />;
}
