import { ReactElement, useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, NonIdealState, Spinner } from '@blueprintjs/core';
import { useOpenApi } from '../../../core/providers';
import { Task, User } from '@techshift/callie-private-api-typescript';
import { parseApiError } from '@techshift/react-core';
import { AppToaster } from '../../../core/util';
import { _TaskIdLoadedPage } from './_task-id-loaded.page';
import {
	BatteryProvider,
	LocationsProvider,
	TaskLogsProvider,
	TasksProvider
} from '../../../tasks/providers';

export function TaskIdPage(): ReactElement {
	const { taskId } = useParams();

	const { openApi } = useOpenApi();
	const openApiRef = useRef(openApi);
	openApiRef.current = openApi;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [task, setTask] = useState<Task | null>(null);
	const [user, setUser] = useState<User | null>(null);

	const fetch = useCallback((id: string) => {
		setTask(null);
		setLoading(true);
		setError('');
		openApiRef.current.tasks
			.getTask(id)
			.then((response) => {
				setTask(response.data.data.task);
				return openApiRef.current.users.getUser(response.data.data.task.userId);
			})
			.then((response) => {
				setUser(response.data.data.user);
			})
			.catch((error) => {
				parseApiError({
					error,
					unknownError: () => {
						setError('Unknown error');
					},
					requestError: () => {
						setError('Unable to reach server');
					},
					responseError: ({ response }) => {
						if (response.data.message === 'TASK_NOT_FOUND') {
							setError('Task not found');
						} else if (response.data.message === 'USER_NOT_FOUND') {
							setError('User not found');
						} else {
							setError('Unknown error');
						}
					}
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		if (error) {
			AppToaster.show({ message: error, intent: 'danger' });
		}
	}, [error]);

	useEffect(() => {
		if (!taskId) {
			return;
		}

		fetch(taskId);
	}, [taskId, fetch]);

	return (
		<div style={{ flex: 1, paddingTop: 25, paddingLeft: 25, display: 'flex' }}>
			{loading && <NonIdealState icon={<Spinner />} title={'Loading'} />}
			{error && (
				<NonIdealState
					icon={'error'}
					title={'Error'}
					description={error}
					action={
						<Button
							outlined
							onClick={() => {
								if (taskId) {
									fetch(taskId);
								}
							}}
						>
							Retry
						</Button>
					}
				/>
			)}

			{!loading && task && user && (
				<TasksProvider task={task} user={user}>
					<TaskLogsProvider taskId={task.id}>
						<LocationsProvider taskId={task.id}>
							<BatteryProvider taskId={task.id}>
								<_TaskIdLoadedPage />
							</BatteryProvider>
						</LocationsProvider>
					</TaskLogsProvider>
				</TasksProvider>
			)}
		</div>
	);
}
